import { captureException } from "@sentry/vue";
import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";

export default {
  namespaced: true,

  state: {
    organizationDetails: [],
    success: "",
    error: "",
    shop: {},
    walkthroughModal: false,
    initialstep: true,
    myAccountWalk: false,
    dashboardSteps: 1,
    cancelPlanConfirmModal: false,
    accountDetails: {},
    fbList: [],
  },

  mutations: {
    set_cancelPlanConfirmModal(state, data) {
      state.cancelPlanConfirmModal = data;
    },
    set_organizationDetails(state, data) {
      state.organizationDetails = data;
    },
    set_success(state, data) {
      state.success = data;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_shops(state, data) {
      state.shop = data;
    },
    set_walkthroughModal(state, data) {
      state.walkthroughModal = data;
    },
    set_initialstep(state, data) {
      state.initialstep = data;
    },
    set_myAccountWalk(state, data) {
      state.myAccountWalk = data;
    },
    set_dashboardStep(state, data) {
      state.dashboardSteps = data;
    },
    set_accountDetails(state, data) {
      state.accountDetails = data;
    },
    set_facebookIdList(state, data) {
      state.fbList = data;
    },
  },

  actions: {
    //get organization details of user
    async getOrganizationDetails({ commit }, data) {
      const organizationId = encryptor.decrypt(
        localStorage.getItem("organizationId")
      );
      const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      const response = await services.apiCallWithGetData(
        `/shop/${organizationId}/${shopId}`
      );
      if (
        response.data.data.organization &&
        response.data.data.shop &&
        response.data.status == 200
      ) {
        commit("set_organizationDetails", response.data.data.organization);
        commit("set_accountDetails", response.data.data.account);
        commit("set_shops", response.data.data.shop);
        commit("set_success", response.data.message);
      } else {
        commit("set_organizationDetails", []);
        commit("set_shops", {});
        commit("set_error", response.data.message || "Something Went Wrong");
      }
    },
    async getFacebookAdsById({ commit }, ownership) {
      const response = await services.apiCallWithGetData(
        `/account/getFacebookAdsById/${ownership}`
      );
      if (response.data.status === 200 && response.data.data.length) {
        commit("set_facebookIdList", response.data.data);
      } else {
        commit("set_facebookIdList", []);
      }
    },

    async uploadShopLogo({ commit }, data) {
      try {
        return await services.apiCallWithData("/shop/upload/shopLogo", data);
      } catch (err) {
        captureException("Error uploading shop logo", err);
        console.log("Error in uploading shop logo.", err);
        throw new Error("Error uploading shop logo", err);
      }
    },
  },
};
