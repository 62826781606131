import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    error: "",
    success: "",
    userInfo: "",
  },

  mutations: {
    set_error(state, data) {
      state.error = data;
    },
    set_success(state, data) {
      state.success = data;
    },
    set_userInfo(state, data) {
      state.userInfo = data;
    },
  },

  actions: {
    async setSignConsent({ commit }, data) {
      let userId = encryptor.decrypt(localStorage.getItem("id"));
      data.userId = userId;
      if (encryptor.decrypt(localStorage.getItem("shopifyUrl"))) {
        data.shopifyUrl = encryptor.decrypt(localStorage.getItem("shopifyUrl"));
      }
      const response = await services.userApiCallWithData(
        "/user/signConsent",
        data
      );
      if (response && response.data && response.data.status == 200) {
        commit("set_error", "");
      } else if (response && response.data && response.data.status == 400) {
        commit("set_error", response.data.message);
      } else {
        commit(
          "set_error",
          response.data.message || response.data.error || "Something went wrong"
        );
      }
    },
    async getUserDetails({ commit }, data) {
      const response = await services.userApiCallGetWithoutAccessToken(
        `/user/getUserDetails/${data}`,
        data
      );
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.status == 200
      ) {
        commit("set_userInfo", response.data.data);
      } else if (
        response &&
        response.data &&
        (response.data.status == 400 || response.data.status == 404) &&
        response.data.message
      ) {
        commit("set_error", response.data.message);
      } else {
        commit(
          "set_error",
          response.data.message || response.data.error || "Something Went Wrong"
        );
      }
    },
    async signOut({ commit }) {
      localStorage.clear();
      router.replace("/get-started");
      router.go();
    },
  },
};
