import services from "../../services/apiServices";
import encryptor from "../plugins/encryption";

export default {
  namespaced: true,

  state: {
    step: 1,
    error: "",
    success: "",
    countries: [],
    collections: [],
    newcollections: [],
    texanomyName: [],
    productCount: 0,
    storeStepsCompleteStatus: false,
    loading: false,
  },

  mutations: {
    SET_STEP(state, step) {
      state.step = step;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_success(state, data) {
      state.success = data;
    },
    set_countries(state, data) {
      state.countries = data;
    },
    set_collections(state, data) {
      state.collections = data;
    },
    set_newcollections(state, data) {
      state.newcollections = data;
    },
    set_texanomy(state, data) {
      state.texanomyName = data;
    },
    set_productCount(state, data) {
      state.productCount = data;
    },
    set_storeStepComplete(state, data) {
      state.storeStepsCompleteStatus = data;
    },
    set_loading(state, data) {
      state.loading = data;
    },
  },

  actions: {
    async editFacebookadsId({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/shop/updateFacebookAdsId",
          data
        );
        return response;
      } catch (err) {}
    },
    async getStoreStepsCompleteStatus({ commit }, data) {
      try {
        commit("set_loading", true);
        const shopId = localStorage.getItem("shopId")
          ? encryptor.decrypt(localStorage.getItem("shopId"))
          : "";
        data.shopId = shopId;
        const response = await services.apiCallWithData(
          "/organization/storeSetup",
          data
        );
        commit("set_loading", false);
        if (response.data.status === 200) {
          commit("set_storeStepComplete", response.data.data.storeSetup);
          commit("set_error", "");
        } else if (response.data.status === 400) {
          commit("set_error", response.data.message);
          commit("set_storeStepComplete", false);
        } else {
          commit("set_error", "Something went wrong");
          commit("set_storeStepComplete", false);
        }
      } catch (err) {}
    },
    //industry dropdown to select industry when updating collection
    async getTaxanomyByName({ commit }, name) {
      try {
        commit("set_loading", true);
        const response = await services.apiCallWithData(
          "/shop/getTaxanomyByName",
          { name: name }
        );
        commit("set_loading", false);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.status &&
          response.data.status == 200
        ) {
          commit("set_texanomy", response.data.data);
          commit("set_error", "");
        }
      } catch (err) {
        commit("set_texanomy", []);
        organization;
      }
    },

    //update collection via adding industry
    async updateCollections({ commit }, data) {
      try {
        commit("set_loading", true);
        const response = await services.apiCallWithData(
          "/shop/updateCollections",
          { collections: data }
        );
        commit("set_loading", false);
        if (
          response &&
          response.data &&
          response.data.newCollections &&
          response.status &&
          response.status == 200
        ) {
          commit("set_newcollections", response.data.newcollections);
          commit("set_error", "");
        } else {
          commit("set_error", "Unable to Update Collections");
          commit("set_newcollections", []);
        }
      } catch (err) {}
    },

    //get all the collections for step 3
    async getIndustryCollections({ commit }, data) {
      try {
        commit("set_loading", true);
        const response = await services.apiCallWithData(
          "/shop/getAllCollections",
          { shopId: data }
        );
        commit("set_loading", false);
        if (
          response &&
          response.data &&
          response.data.collections &&
          response.status &&
          response.status == 200
        ) {
          commit("set_collections", response.data.collections);
          commit("set_error", "");
        } else {
          commit("set_error", "Unable to get Collections");
          commit("set_collections", []);
        }
      } catch (error) {
        console.error(error);
      }
    },

    //verify Meta ads account Id
    async connectFacebookStore({ commit }, data) {
      try {
        commit("set_loading", true);
        const response = await services.apiCallWithData(
          "/shop/saveBusinessManager",
          data
        );
        commit("set_loading", false);
        return response;
      } catch (err) {}
    },

    //In storeSetup update organization details for user - step2 & step3
    async updateOrganizationDetails({ commit, state }, data) {
      try {
        const organizationId = encryptor.decrypt(
          localStorage.getItem("organizationId")
        );
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        data.organizationId = organizationId;
        data.shopId = shopId;
        commit("set_loading", true);
        const response = await services.apiCallWithData(
          "/shop/updateShopDetails",
          data
        );
        commit("set_loading", false);
        return response;
      } catch (err) {}
    },

    async getProductCount({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/shop/getProductCount",
          {
            shopId: data,
          }
        );
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit("set_productCount", response.data.data);
          commit("set_error", "");
        }
      } catch (err) {
        commit("set_productCount", 0);
      }
    },

    async getShopifyDataFetchingStatus({ commit }) {
      try {
        const response = await services.apiCallWithData(
          "/shop/getProductStatus"
        );
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status === 200 &&
          response.data.data
        ) {
          return { data: response.data.data, message: "" };
        } else {
          return {
            data: {},
            message: "Something went wrong",
          };
        }
      } catch (err) {
        return {
          data: {},
          message: "Internal Server Error",
        };
      }
    },
    async checkShopStatus({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/shop/checkShopStatus",
          {
            shopId: data,
          }
        );
        return response;
      } catch (err) {
        return err;
      }
    },
  },
};
