import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";

export default {
  namespaced: true,
  state: {
    changePasswordDialog: false,
    success: "",
    error: "",
  },
  mutations: {
    set_changePasswordDialog(state, data) {
      state.changePasswordDialog = data;
    },
    set_success(state, data) {
      state.success = data;
    },
    set_error(state, data) {
      state.error = data;
    },
  },
  actions: {
    async changePassword({ commit }, data) {
      const response = await services.userApiCallWithData(
        "/user/resetPassword",
        data
      );
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status == 200 &&
        response.data.data
      ) {
        commit("set_success", response.data.data);
      } else if (
        response &&
        response.data &&
        response.data.message &&
        response.data.status &&
        (response.data.status == 400 || response.data.status == 500)
      ) {
        commit("set_error", response.data.message);
      } else {
        commit("set_error", "Something went wrong");
      }
    },
  },
};
