import {
  googleWidgetList,
  facebookWidgetList,
  shortenWidgets,
  shortenWidgetColumnList,
} from "@/data/widget-list";

export const clickToScrollToWidget = (id) => {
  const offset = 80; //height of top-bar
  const targetEl = document.getElementById(id);

  if (!targetEl) alert(`${id} not found`);

  window.scrollTo({
    behavior: "smooth",
    top:
      targetEl.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  });
};

export const getUniqueWidgetKey = (options) => {
  /**
   * displayName
   * graphConfig?: Either graphConfig or optionsPlatformId can exist
   * optionsPlatformId?
   * isAmazon?
   */
  const { displayName, graphConfig, optionsPlatformId, isAmazon } = options;
  if (isAmazon) return `AMAZON-${displayName}`;

  let platformId;
  if (graphConfig) platformId = graphConfig?.split(":")?.[1];
  //GA4:[65c1e4178d4a7ab693bb85f3]:userPie
  else platformId = optionsPlatformId;

  if (platformId && platformId !== "null") {
    return `${platformId}-${displayName}`;
  } else {
    return displayName;
  }
};

export const getGoogleWidgets = (response) => {
  return response.filter((widget) => {
    if (googleWidgetList.indexOf(widget.name) !== -1) return widget;
  });
};

export const getFacebookWidgets = (response) => {
  return response.filter((widget) => {
    if (facebookWidgetList.indexOf(widget.name) !== -1) return widget;
  });
};

export const transformFacebookWidgets = (facebookWidgets) => {
  const data = facebookWidgets.reduce((result, item) => {
    const desired = {
      platformId: item?.platformId,
      accountId: item?.accountId,
      accountName: item?.accountName,
      campaign: item?.name === "Campaign" ? item : {},
      adset: item?.name === "Adset" ? item : {},
      ads: item?.name === "Creative" ? item : {},
    };

    const existingIndex = result.findIndex(
      (resItem) => resItem.platformId === item.platformId
    );
    if (existingIndex >= 0) {
      if (item.name === "Campaign") result[existingIndex].campaign = item;
      if (item.name === "Adset") result[existingIndex].adset = item;
      if (item.name === "Creative") result[existingIndex].ads = item;
    } else {
      result.push(desired);
    }

    return result;
  }, []);

  return data;
};

export const transformGoogleWidgets = (googleWidgets) => {
  const data = googleWidgets.reduce((result, item) => {
    const desired = {
      platformId: item?.platformId,
      accountId: item?.accountId,
      accountName: item?.accountName,

      searchOverview: item?.name === "Search Overview" ? item : {},
      pmaxOverview: item?.name === "Performance Max Overview" ? item : {},
      pmaxListingGroup: item?.name === "Pmax Listing Group" ? item : {},
      topPerformingKeywords:
        item?.name === "Top Performing Keywords" ? item : {},
      adQuality: item?.name === "Ad Quality" ? item : {},
      videoOverview: item?.name === "Video Overview" ? item : {},
      videoViews: item?.name === "Video Views" ? item : {},
      shoppingOverview: item?.name === "Shopping Overview" ? item : {},
      shoppingListingGroup: item?.name === "Shopping Listing Group" ? item : {},
    };

    const existingIndex = result.findIndex(
      (resItem) => resItem.platformId === item.platformId
    );
    if (existingIndex >= 0) {
      if (item.name === "Search Overview")
        result[existingIndex].searchOverview = item;
      if (item.name === "Performance Max Overview")
        result[existingIndex].pmaxOverview = item;
      if (item.name === "Pmax Listing Group")
        result[existingIndex].pmaxListingGroup = item;
      if (item.name === "Top Performing Keywords")
        result[existingIndex].topPerformingKeywords = item;
      if (item.name === "Ad Quality") result[existingIndex].adQuality = item;
      if (item.name === "Video Overview")
        result[existingIndex].videoOverview = item;
      if (item.name === "Video Views") result[existingIndex].videoViews = item;
      if (item.name === "Shopping Overview")
        result[existingIndex].shoppingOverview = item;
      if (item.name === "Shopping Listing Group")
        result[existingIndex].shoppingListingGroup = item;
    } else {
      result.push(desired);
    }

    return result;
  }, []);

  return data;
};

export const getWidgetListInOrder = (categories) => {
  const flatObject = [{ displayName: "Overall Stats" }];

  categories.forEach((category) => {
    // for subcategories
    if (category.subcategories) {
      category.subcategories.forEach((subcategory) => {
        subcategory.fields.forEach((subcategoryField, index) => {
          //overview
          if (index === 0) {
            flatObject.push({
              displayName: subcategoryField.value,
              platformName: category?.value?.toLowerCase(),
            });
          }

          if (subcategoryField.subfields) {
            subcategoryField.subfields.forEach((subcategorySubfield) => {
              const platformId =
                subcategorySubfield.graphConfig?.split(":")?.[1] ?? null;

              const obj = {
                displayName: subcategorySubfield.value,
                platformName: category?.value?.toLowerCase(),
                ...(platformId && platformId !== "null" ? { platformId } : {}),
              };
              flatObject.push(obj);
            });
          }
        });
      });
    }
    if (category.fields) {
      category.fields.forEach((categoryField) => {
        const platformId = categoryField.graphConfig?.split(":")?.[1] ?? null;
        const obj = {
          displayName: categoryField.value,
          platformName: category?.value?.toLowerCase(),
          ...(platformId && platformId !== "null" ? { platformId } : {}),
        };
        flatObject.push(obj);
      });
    }
  });

  return flatObject;
};

export function capitalizeFirstLetter(string) {
  if (string.length <= 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const checkForDataInWidget = (widget) => {
  if (
    widget?.chart &&
    Array.isArray(widget?.chart) &&
    widget?.chart?.length <= 1
  ) {
    return false;
  }

  //if widget.chart is object
  else if (
    widget?.chart &&
    widget?.chart.constructor === Object &&
    Object.keys(widget?.chart)?.length <= 1
  ) {
    return false;
  } else return true;
};

export const setDataInWidget = (widget) => {
  let widgetData = widget;
  if (shortenWidgets.indexOf(widgetData.name) !== -1) {
    const shortenList = shortenWidgetColumnList;
    let shortenWidgetObj = shortenList.filter((item) => {
      return item.name == widgetData.name;
    });
    let finalChart = [];
    let positions = [];
    for (let i = 0; i < shortenWidgetObj.length; i++) {
      finalChart.push(shortenWidgetObj[i].headers);
      positions = shortenWidgetObj[i].position;
    }
    const data = widgetData.chart;
    if (widgetData.name === "FBAdsPlatform") {
      let flattenedArray = data.slice(1, data.length).flat(1);
      for (const flatArray of flattenedArray) {
        let rowData = [];
        for (const position of positions) {
          rowData.push(flatArray[position]);
        }
        finalChart.push(rowData);
      }
    } else {
      for (const row of data.slice(1, data.length)) {
        let rowData = [];
        for (const position of positions) {
          rowData.push(row[position]);
        }
        finalChart.push(rowData);
      }
    }
    widgetData.chart = finalChart;
    return widgetData;
  }
  return widgetData;
};
