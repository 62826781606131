import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";

export default {
  namespaced: true,

  state: {
    limit: 5,
    page: 1,
    storeList: [
      {
        shopDetails: {
          name: "",
        },
      },
    ],
    totalCount: 0,
    newAccessToken: "",
    sideDrawerOpen: true,
  },
  mutations: {
    setAllStoreDetails(state, data) {
      state.storeList = data.results;
      state.totalCount = data.totalCount;
    },
    incPage(state) {
      state.page = ++state.page;
    },
    set_newAccessToken(state, data) {
      state.newAccessToken = data;
    },
    setSideDrawerOpen(state, data) {
      state.sideDrawerOpen = data;
    },
  },
  actions: {
    async updateAccessTokenByShopId({ commit }, data) {
      const response = await services.apiCallWithData(
        "/user/updateAccessTokenByShopId",
        data
      );

      if (response.data.token) {
        commit("set_newAccessToken", response.data.token);
      } else {
        commit("set_newAccessToken", "");
      }
    },
    async getStoreList({ commit, state }, data) {
      const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      const organizatioId = encryptor.decrypt(
        localStorage.getItem("organizationId")
      );
      data.organizationId = organizatioId;
      data.shopId = shopId;
      data.limitNo = state.limit;
      const response = await services.apiCallWithData(
        "/shop/storeListByOrganization",
        data
      );
      if (response.data.status == 200) {
        commit("setAllStoreDetails", response.data.data);
      } else {
        commit("setAllStoreDetails", { results: [], totalCount: 0 });
      }
    },
  },
};
