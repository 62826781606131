/* eslint-disable no-unused-vars */
import services from "../../services/apiServices";

export default {
  namespaced: true,

  state: {
    platforms: [],
    maxAccounts: {},
    removeConfirmModal: false,
    deleteConfirmModal: false,
    accountDetailsForDelete: {},
  },

  mutations: {
    set_platforms(state, data) {
      state.platforms = data;
    },
    set_maxAccounts(state, data) {
      state.maxAccounts = data;
    },
    set_removeConfirmModal(state, data) {
      state.removeConfirmModal = data;
    },
    set_deleteConfirmModal(state, data) {
      state.deleteConfirmModal = data;
    },
    set_accountDetailsForDelete(state, data) {
      state.accountDetailsForDelete = data;
    },
  },

  actions: {
    async getPlatformList({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/platform/listPlatforms",
          data
        );
        commit("set_platforms", response.data?.data?.platforms);

        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async getShopifyPlatformList({ commit }, data) {
      const platformType = data?.platformType;

      try {
        const response = await services.apiCallWithGetData(
          `/platform/getPlatformList?platformType=${platformType}`
        );
        commit("set_platforms", [response.data?.data?.platformInfo]);

        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async getMaxAdAccounts({ commit }, data) {
      const platformType = data?.platformType;
      try {
        const response = await services.getApiCallForReporting(
          `/platform/getMaxAdAccounts/platformType?platformType=${platformType}&processType=${platformType}`
        );
        commit("set_maxAccounts", response.data?.data?.accounts);

        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async deletePlatform({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/platform/deletePlatform",
          data
        );

        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async removePlatform({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/platform/removePlatform",
          data
        );

        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async addFacebookPlatforms({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/platform/addFacebookPlatforms",
          data
        );

        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async addGoogleAdsPlatforms({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/platform/addGoogleAdsPlatforms",
          data
        );

        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async changePlatformStatus({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/platform/changePlatformStatus",
          data
        );

        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
