const getCleanAndParseFloat = (input) => {
  if (typeof input !== "string") {
    if (input == 0) {
      return 0;
    }
    return parseFloat(input);
  }
  if (input === "0") return 0;
  const cleaned = input.replace(/[^0-9.]/g, "").replace(/,/g, "");
  if (cleaned === "") return 0;
  if (cleaned === "0") return 0;
  return parseFloat(cleaned);
};

const setCategoriesWidget = (reports, categoryIndex) => {
  if (categoryIndex !== -1) {
    let categoryData = reports[categoryIndex].chart;

    const revenueData = categoryData
      .slice(1, -2)
      .map((row) => row.slice(4))
      .flat();

    const roundDownToMultiple = (value, multiple) => {
      return Math.floor((value / multiple) * multiple);
    };

    const roundedValues = Array.from(
      new Set(
        revenueData.map((value) =>
          roundDownToMultiple(getCleanAndParseFloat(value), 1000)
        )
      )
    );

    const highestValue = Math.max(...roundedValues);

    if (highestValue == 0) {
      reports[categoryIndex].chart = [["Product Category"]];
    }

    return reports;
  }
  return reports;
};

export { setCategoriesWidget };
