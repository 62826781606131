import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";

export default {
  namespaced: true,

  state: {
    error: "",
    success: "",
    organizationId: "",
  },

  mutations: {
    set_error(state, data) {
      state.error = data;
    },
    set_success(state, data) {
      state.success = data;
    },
    set_data(state, data) {
      state.organizationId = data;
    },
  },

  actions: {
    //on onboarding page to set the organization name for user
    async setStoreName({ commit }, payload) {
      const response = await services.apiCallWithData(
        "/organization/createOrganizationByName",
        payload
      );
      if (
        response.data.status == 200 &&
        response.data.data &&
        response.data.data.organizationId &&
        response.data.data.shopId
      ) {
        localStorage.setItem(
          "organizationId",
          encryptor.encrypt(response.data.data.organizationId)
        );
        localStorage.setItem(
          "shopId",
          encryptor.encrypt(response.data.data.shopId)
        );
        commit("set_data", response.data.data.organizationId);
        commit("set_success", response.data.message);
      } else if (response.data.status == 400) {
        commit("set_error", response.data.message);
      } else {
        commit("set_error", "Something Went Wrong");
      }
    },
    async disconnectAccount({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/platform/disconnectAccount",
          data
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
