/* eslint-disable no-unused-vars */
import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";
import split from "lodash/split";
import round from "lodash/round";
import moment from "moment";
import { getHiddenWidgetsLS, setHiddenWidgetsLS } from "@/utils/local-storage";
import {
  getHiddenWidgetsForReportsPage,
  updateCheckedStateForWidgets,
} from "@/utils/hidden-widgets";
import { getUniqueWidgetKey, getWidgetListInOrder, checkForDataInWidget } from "@/utils/util-fns";
import { setCategoriesWidget } from "@/utils/conditional-widgets";
import { captureException } from "@sentry/vue";
import { EventBus } from "@/utils/event-bus";

export default {
  namespaced: true,

  state: {
    uniquePlatformNames: [],
    isCategoryDrawerOpen: true,
    isFetchingAdAccountsForPlatform: false,
    error: "",
    shopifyReportSortData: [],
    ecommerceReportSortData: [],
    amazonReportSortData: [],
    updateAmazonData: [],
    amazonReportData: null,
    stateDetails: {},
    keys: [],
    fbIdNameList: [],
    fbError: "",
    profileIdNameList: [],
    profileError: "",
    profileIdSellerNameList: [],
    profileSellerError: "",
    GAAccountsList: [],
    GAError: "",
    GAWebPropertyList: [],
    GAViewList: [],
    reportingSetup: {},
    facebookReauthenticate: null,
    googleAdsCustomerList: [],
    GAdsError: "",
    setupStatusError: null,
    reportData: null,
    shopifyReportData: [],
    levelData: [],
    reportDates: {
      start: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
      end: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
      //  + "T00:00:00+05:30"
    },
    ecommerceReportDates: {
      start: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
      end: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
      //  + "T00:00:00+05:30",
    },
    isEcommerceReportDatesChanged: false,
    loading: false,
    reportLoadingModal: true,
    //schedular
    schedularModal: false,
    shareModal: false,
    levelDataModal: false,
    scheduleEmails: null,
    scheduleSlack: null,
    scheduleMobiles: "",
    scheduleSms: "",
    selectedTimeZone: "Asia/Kolkata",
    startDate: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
    startTimeList: [
      "8:00",
      "8:30",
      "9:00",
      "9:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
    ],
    weeks: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    repeatList: [
      "Daily",
      "Weekly",
      "Monthly on the 1st Monday (Last month)",
      "Monthly on the 24th day (Last 30 days)",
    ],
    timeZoneList: [
      { text: "Atlantic/Azores", offset: "(GMT-01:00)" },
      {
        text: "Atlantic/Cape_Verde",
        offset: "(GMT-01:00) Cape Verde Islands",
      },
      { text: "Atlantic/South_Georgia", offset: "(GMT-02:00) Mid-Atlantic" },
      { text: "America/Sao_Paulo", offset: "(GMT-03:00) Brasilia" },
      {
        text: "America/Argentina/Buenos_Aires",
        offset: "(GMT-03:00) Buenos Aires, Georgetown",
      },
      { text: "America/Godthab", offset: "(GMT-03:00) Greenland" },
      {
        text: "America/St_Johns",
        offset: "(GMT-03:30) Newfoundland and Labrador",
      },
      { text: "America/Halifax", offset: "(GMT-04:00) Atlantic Time (Canada)" },
      { text: "America/La_Paz", offset: "(GMT-04:00) Caracas, La Paz" },
      { text: "America/Cuiaba", offset: "(GMT-04:00) Manaus" },
      { text: "America/Santiago", offset: "(GMT-04:00) Santiago" },
      { text: "America/Bogota", offset: "(GMT-05:00) Bogota, Lima, Quito" },
      {
        text: "America/New_York",
        offset: "(GMT-05:00) Eastern Time (US and Canada)",
      },
      {
        text: "America/Indiana/Indianapolis",
        offset: "(GMT-05:00) Indiana (East)",
      },
      { text: "America/Costa_Rica", offset: "(GMT-06:00) Central America" },
      {
        text: "America/Chicago",
        offset: "(GMT-06:00) Central Time (US and Canada)",
      },
      {
        text: "America/Monterrey",
        offset: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
      },
      { text: "America/Edmonton", offset: "(GMT-06:00) Saskatchewan" },
      { text: "America/Phoenix", offset: "(GMT-07:00) Arizona" },
      {
        text: "America/Chihuahua",
        offset: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
      },
      {
        text: "America/Denver",
        offset: "(GMT-07:00) Mountain Time (US and Canada)",
      },
      {
        text: "America/Tijuana",
        offset: "(GMT-08:00) Pacific Time (US and Canada); Tijuana",
      },
      { text: "America/Anchorage", offset: "(GMT-09:00) Alaska" },
      { text: "Pacific/Honolulu", offset: "(GMT-10:00) Hawaii" },
      { text: "Pacific/Apia", offset: "(GMT-11:00) Midway Island, Samoa" },
      { text: "Africa/Monrovia", offset: "(GMT) Casablanca, Monrovia" },
      {
        text: "Europe/London",
        offset: "(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
      },
      {
        text: "Europe/Berlin",
        offset: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      },
      {
        text: "Europe/Belgrade",
        offset: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      },
      {
        text: "Europe/Paris",
        offset: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
      },
      {
        text: "Europe/Belgrade",
        offset: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      },
      { text: "Africa/Lagos", offset: "(GMT+01:00) West Central Africa" },
      {
        text: "Europe/Istanbul",
        offset: "(GMT+02:00) Athens, Bucharest, Istanbul",
      },
      { text: "Africa/Cairo", offset: "(GMT+02:00) Cairo" },
      { text: "Africa/Harare", offset: "(GMT+02:00) Harare, Pretoria" },
      {
        text: "Europe/Riga",
        offset: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
      },
      { text: "Asia/Jerusalem", offset: "(GMT+02:00) Jerusalem" },
      { text: "Europe/Minsk", offset: "(GMT+02:00) Minsk" },
      { text: "Africa/Windhoek", offset: "(GMT+02:00) Windhoek" },
      { text: "Asia/Baghdad", offset: "(GMT+03:00) Baghdad" },
      { text: "Asia/Kuwait", offset: "(GMT+03:00) Kuwait, Riyadh" },
      {
        text: "Europe/Moscow",
        offset: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
      },
      { text: "Africa/Nairobi", offset: "(GMT+03:00) Nairobi" },
      { text: "Asia/Tehran", offset: "(GMT+03:30) Tehran" },
      { text: "Asia/Muscat", offset: "(GMT+04:00) Abu Dhabi, Muscat" },
      { text: "Asia/Baku", offset: "(GMT+04:00) Baku" },
      { text: "Asia/Tbilisi", offset: "(GMT+04:00) Tblisi" },
      { text: "Asia/Yerevan", offset: "(GMT+04:00) Yerevan" },
      { text: "Asia/Kabul", offset: "(GMT+04:30) Kabul" },
      { text: "Asia/Yekaterinburg", offset: "(GMT+05:00) Ekaterinburg" },
      {
        text: "Asia/Tashkent",
        offset: "(GMT+05:00) Islamabad, Karachi, Tashkent",
      },
      {
        text: "Asia/Kolkata",
        offset: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      },
      { text: "Asia/Kathmandu", offset: "(GMT+05:45) Kathmandu" },
      {
        text: "Asia/Novosibirsk",
        offset: "(GMT+06:00) Almaty, Novosibirsk",
      },
      { text: "Asia/Almaty", offset: "(GMT+06:00) Astana, Dhaka" },
      { text: "Asia/Colombo", offset: "(GMT+06:00) Sri Jayawardenepura" },
      { text: "Asia/Rangoon", offset: "(GMT+06:30) Yangon (Rangoon)" },
      { text: "Asia/Bangkok", offset: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
      { text: "Asia/Krasnoyarsk", offset: "(GMT+07:00) Krasnoyarsk" },
      {
        text: "Asia/Shanghai",
        offset: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
      },
      { text: "Asia/Irkutsk", offset: "(GMT+08:00) Irkutsk, Ulaanbaatar" },
      { text: "Asia/Singapore", offset: "(GMT+08:00) Kuala Lumpur, Singapore" },
      { text: "Australia/Perth", offset: "(GMT+08:00) Perth" },
      { text: "Asia/Taipei", offset: "(GMT+08:00) Taipei" },
      {
        text: "Asia/Tokyo",
        offset: "(GMT+09:00) Osaka, Sapporo, Tokyo",
      },
      { text: "Asia/Seoul", offset: "(GMT+09:00) Seoul" },
      { text: "Asia/Yakutsk", offset: "(GMT+09:00) Yakutsk" },
      { text: "Australia/Adelaide", offset: "(GMT+09:30) Adelaide" },
      { text: "Australia/Darwin", offset: "(GMT+09:30) Darwin" },
      { text: "Australia/Brisbane", offset: "(GMT+10:00) Brisbane" },
      {
        text: "Australia/Sydney",
        offset: "(GMT+10:00) Canberra, Melbourne, Sydney",
      },
      { text: "Pacific/Guam", offset: "(GMT+10:00) Guam, Port Moresby" },
      { text: "Australia/Hobart", offset: "(GMT+10:00) Hobart" },
      { text: "Asia/Vladivostok", offset: "(GMT+10:00) Vladivostok" },
      {
        text: "Pacific/Guadalcana",
        offset: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
      },
      { text: "Pacific/Auckland", offset: "(GMT+12:00) Auckland, Wellington" },
      {
        text: "Pacific/Fiji",
        offset: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
      },
      { text: "Pacific/Tongatapu", offset: "(GMT+13:00) Nuku'alofa" },
    ],

    dayList: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
    ],
    monthList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    startTime: "8:00",
    repeatReport: "Daily",
    daySelected: "1",
    weekSelected: "Mon",
    monthSelected: "1",
    updateScheduleData: {},
    updateLevelData: [],
    showAmazonSchedular: true,
    showEcommerceSchedular: true,
    shareEmails: null,
    shareMobiles: "",
    reportType: "",
    dates: {},
    defaultResponse: false,
    shopifyResponse: false,
    formattedPercent: 0,

    // platform list categories
    categories: [],
    amazonCategories: [],
    hiddenWidgets: {},
    maxAdsAccount: {},
    fbConfirmModal: false,
    googleAdsConfirmModal: false,
  },

  getters: {
    currentDateInfo(state) {
      const dateTime = new Date();
      let hours = dateTime.getHours();
      let minutes = dateTime.getMinutes();
      let month = dateTime.getMonth();
      let date = dateTime.getDate();
      let weekday = dateTime.getDay();
      let updatedHours = hours;
      let updatedMinutes = minutes;
      if (updatedHours == 24) {
        updatedHours = 0;
      }
      if (updatedMinutes <= 30 && updatedMinutes > 0) {
        updatedHours = updatedHours + 1;
        updatedMinutes = "00";
      } else {
        updatedHours = updatedHours + 1;
        updatedMinutes = "30";
      }
      if (updatedHours >= 0 && updatedHours < 8) {
        updatedHours = 8;
        updatedMinutes = "00";
      }
      const updatedTime = `${updatedHours}:${updatedMinutes}`;
      return {
        date: dateTime,
        hours: hours,
        minutes: minutes,
        dateNumber: date,
        weekday: state.weeks[weekday],
        month: month,
        updatedHours: updatedHours,
        updatedMinutes: updatedMinutes,
        updatedTime: updatedTime,
      };
    },
    getCleanAndParseFloat: (state) => (input) => {
      if (typeof input !== "string") {
        if (input == 0) {
          return 0;
        }
        return parseFloat(input);
      }
      if (input === "0") return 0;
      const cleaned = input.replace(/[^0-9.]/g, "").replace(/,/g, "");
      if (cleaned === "") return 0;
      if (cleaned === "0") return 0;
      return parseFloat(cleaned);
    },
  },

  mutations: {
    set_unique_platform_names(state, data) {
      state.uniquePlatformNames = data;
    },
    set_isFetchingAdAccountsForPlatform(state, data) {
      state.isFetchingAdAccountsForPlatform = data;
    },
    set_isCategoryDrawerOpen(state, data) {
      state.isCategoryDrawerOpen = data;
    },

    set_hiddenWidgets(state, { data, reportType }) {
      const oldHw = getHiddenWidgetsLS(reportType);
      const updated = {
        ...oldHw,
        ...data,
      };
      state.hiddenWidgets = updated;
      setHiddenWidgetsLS(reportType, updated);
    },
    set_categories(state, data) {
      const { reportType, categories } = data;
      if (reportType === "Ecommerce") state.categories = categories;
      if (reportType === "Amazon") state.amazonCategories = categories;
    },
    set_showAmazonSchedularOption(state, data) {
      state.showAmazonSchedular = data;
    },
    set_showSchedularOption(state, data) {
      state.showEcommerceSchedular = data;
    },
    set_UpdateScheduleData(state, data) {
      state.updateScheduleData = data;
    },
    set_monthSelected(state, data) {
      state.monthSelected = data;
    },
    set_weekSelected(state, data) {
      state.weekSelected = data;
    },
    set_daySelected(state, data) {
      state.daySelected = data;
    },
    set_repeatReport(state, data) {
      state.repeatReport = data;
    },
    set_scheduleEmails(state, data) {
      state.scheduleEmails = data;
    },
    set_scheduleSlack(state, data) {
      state.scheduleSlack = data;
    },
    set_scheduleMobiles(state, data) {
      state.scheduleMobiles = data;
    },
    set_scheduleSms(state, data) {
      state.scheduleSms = data;
    },
    set_schedularStartTime(state, data) {
      state.startTime = data;
    },
    set_selectedTimeZone(state, data) {
      state.selectedTimeZone = data;
    },
    set_schedularStartDate(state, data) {
      state.startDate = data;
    },
    set_schedularModal(state, data) {
      state.schedularModal = data;
    },
    set_shareModal(state, data) {
      state.shareModal = data;
    },
    set_reportLoadingModal(state, data) {
      state.reportLoadingModal = data;
    },
    set_dates(state, data) {
      state.reportDates = data;
    },
    set_ecommerce_dates(state, data) {
      state.ecommerceReportDates = data;
    },
    set_isEcommerceReportDatesChanged(state, data) {
      state.isEcommerceReportDatesChanged = data;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_stateDetails(state, data) {
      state.stateDetails = data;
    },
    set_keys(state, data) {
      state.keys = data;
    },
    setFbIdName(state, data) {
      state.fbIdNameList = data;
    },
    setFbError(state, data) {
      state.fbError = data;
    },
    setProfileIdSellerName(state, data) {
      state.profileIdSellerNameList = data;
    },
    setProfileSellerError(state, data) {
      state.profileSellerError = data;
    },
    setProfileIdName(state, data) {
      state.profileIdNameList = data;
    },
    setProfileError(state, data) {
      state.profileError = data;
    },
    setGAAccounts(state, data) {
      state.GAAccountsList = data;
    },
    setGAError(state, data) {
      state.GAError = data;
    },
    setGAWebProperty(state, data) {
      state.GAWebPropertyList = data;
    },
    setGAView(state, data) {
      state.GAViewList = data;
    },
    set_setupStatus(state, data) {
      state.reportingSetup = data;
    },
    set_facebookReauthenticate(state, data) {
      state.facebookReauthenticate = data;
    },
    set_googleAdsCustomerList(state, data) {
      state.googleAdsCustomerList = data;
    },
    setGAdsError(state, data) {
      state.GAdsError = data;
    },
    set_setupStatusError(state, data) {
      state.setupStatusError = data;
    },
    set_facebookReauthenticateError(state, data) {
      state.facebookReauthenticateError = data;
    },
    set_reportData(state, data) {
      state.reportData = data;
    },
    set_shopifyReportData(state, data) {
      state.shopifyReportData = data;
    },
    set_levelData(state, data) {
      state.levelData = data;
    },
    set_levelDataModal(state, data) {
      state.levelDataModal = data;
    },
    set_loading(state, data) {
      state.loading = data;
    },
    set_reportType(state, data) {
      state.reportType = data;
    },
    set_shareEmails(state, data) {
      state.shareEmails = data;
    },
    set_shareMobiles(state, data) {
      state.shareMobiles = data;
    },
    SET_amazonSortData(state, newData) {
      state.amazonReportSortData = newData;
    },
    SET_amazonReportData(state, Data) {
      state.amazonReportData = Data;
    },
    SET_updateAmazonData(state, Data) {
      state.updateAmazonData = Data;
    },

    SET_ecommerceSortData(state, Data) {
      state.ecommerceReportSortData = Data;
    },

    SET_shopifyReportSortData(state, Data) {
      state.shopifyReportSortData = Data;
    },

    set_share_dates(state, data) {
      state.dates = data;
    },

    set_default_response(state, data) {
      state.defaultResponse = data;
    },
    set_shopify_response(state, data) {
      state.shopifyResponse = data;
    },
    set_maxAdsAccount(state, data) {
      state.maxAdsAccount = data;
    },
    set_fbConfirmModal(state, data) {
      state.fbConfirmModal = data;
    },
    set_googleAdsConfirmModal(state, data) {
      state.googleAdsConfirmModal = data;
    },
    getFormattedPercent(state, value) {
      let splitValue;
      if (value >= 0) {
        splitValue = value;
      } else {
        let valueArr = split(value, "-");
        splitValue = valueArr[1];
      }

      let roundedValue = round(splitValue);
      if (roundedValue <= 999) {
        state.formattedPercent = roundedValue + "%";
      } else if (roundedValue > 999 && roundedValue <= 99999) {
        state.formattedPercent = round(roundedValue / 1000, 1) + "K%";
      } else {
        state.formattedPercent = round(roundedValue / 100000, 1) + "L%";
      }
    },
  },

  actions: {
    async syncWidgetsWithCategories({ commit, dispatch }, options) {
      const { reportType, apiWidgets } = options;
      const isAmazon = reportType === "Amazon";
      const hiddenWidgets = getHiddenWidgetsLS(reportType) ?? {};

      const response = await dispatch("getGraphConfig", { reportType });
      const graphConfig = response?.data?.data?.selectedGraphs;

      const widgetsInApi = []; //['Top Selling Products',"New vs Old"]

      for (let i = 0; i < apiWidgets?.length; i++) {
        const table = apiWidgets?.[i];

        const key = getUniqueWidgetKey({
          isAmazon,
          displayName: table?.displayName,
          ...(isAmazon
            ? {}
            : {
                optionsPlatformId: table?.platformId,
              }),
        });

        const isDataInWidget = checkForDataInWidget(table);

        if (isDataInWidget) {
          widgetsInApi.push(key);
        } else {
          hiddenWidgets[key] = false;
        }
      }

      //Order is imp: DON'T CHANGE.
      //1
      const { updatedGraphConfig } = updateCheckedStateForWidgets({
        isAmazon,
        widgetList: widgetsInApi,
        newCheckedState: false,
        graphConfig,
      });

      //2
      const updatedHiddenWidgets = getHiddenWidgetsForReportsPage(reportType, updatedGraphConfig);

      commit("set_categories", {
        reportType,
        categories: updatedGraphConfig,
      });
      commit("set_hiddenWidgets", {
        data: { ...hiddenWidgets, ...updatedHiddenWidgets },
        reportType,
      });
    },

    // eslint-disable-next-line no-empty-pattern
    cleanAndParseFloat({}, input) {
      if (typeof input !== "string") {
        if (input == 0) {
          return 0;
        }
        return parseFloat(input);
      }
      const cleaned = input.replace(/[^0-9.]/g, "").replace(/,/g, "");
      return parseFloat(cleaned);
    },

    async updateSchedule({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/schedule/updateSchedule", data);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getReportSchedule({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/schedule/getSchedule", data);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getReportLevelData({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/report/getPaginatedLevelData", data);
        commit("set_levelData", response.data.data.report);
      } catch (error) {
        console.error(error);
      }
    },
    async createSchedule({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/schedule/createSchedule", data);
        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async deleteSchedule({ commit }, { scheduleId }) {
      try {
        const response = await services.deleteApiCallForReporting("/schedule/deleteSchedule", {
          scheduleId,
        });
        return response;
      } catch (error) {
        console.error(error);
        throw error; // Re-throw the error to handle it in the component
      }
    },

    async ecommerceReportSortData({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/report/updateDefaultReport/sort",
          data,
        );
        commit("SET_ecommerceSortData", response?.data?.data?.chart);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async amazonReportSortData({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/amazonreports/updateDefaultReportWithSort",
          data,
        );
        commit("SET_amazonSortData", response);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getDefaultAmazonReportData({ commit, dispatch, state }, data) {
      const amazonCategories = state.amazonCategories;
      try {
        commit("set_loading", true);
        const reportType = "Amazon";
        const response = await services.apiCallForReporting(
          "/amazonreports/getDefaultReport",
          data,
        );
        let amazonReport = response.data.data.report || [];

        const amazonFlatArray = getWidgetListInOrder(amazonCategories);
        let finalReports = amazonFlatArray?.map((widget, index) => {
          const { platformId, platformName, displayName } = widget;

          const matching = amazonReport?.find((report) => {
            if (platformId) {
              if (report.displayName === displayName && report.platformId === platformId) {
                report.placement = index;
                report.platformName = platformName;
                return report;
              }
            } else {
              if (report.displayName === displayName) {
                report.placement = index;
                report.platformName = platformName;
                return report;
              }
            }
          });
          return matching;
        });

        finalReports = finalReports.filter((el) => el != null);

        amazonReport = finalReports;

        amazonReport.sort((a, b) => a.placement - b.placement);

        //sync api widgets with category-list
        dispatch("syncWidgetsWithCategories", {
          reportType,
          apiWidgets: amazonReport,
        });

        commit("SET_amazonReportData", amazonReport);

        // this.$forceUpdate();

        return response;
      } catch (error) {
        console.error(error);
      } finally {
        commit("set_loading", false);
      }
    },

    async generateAmazonReport({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/amazonreports/generateDefaultReport",
          data,
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async updateAmazonReport({ commit }, { startDate, endDate, limit }) {
      const data = {
        startDate,
        endDate,
        limit,
      };
      try {
        const response = await services.apiCallForReporting(
          "/amazonreports/updateDefaultReport",
          data,
        );
        commit("SET_updateAmazonData", response);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getGraphConfig({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/report/graphConfig", data);
        const selectedGraphs = response?.data?.data?.selectedGraphs;
        if (selectedGraphs?.length > 0) {
          commit("set_categories", {
            reportType: data?.reportType,
            categories: selectedGraphs,
          });
        }
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getGraphConfigById({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/report/graphConfigById", data);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async saveGraphConfig({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/report/graphConfig/save", data);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getPlatformList({ commit }, data) {
      const { reportType } = data;

      try {
        const response = await services.apiCallForReporting("/report/platformList", data);
        const renderConfig = response?.data?.data?.renderConfig;
        commit("set_categories", {
          reportType,
          categories: renderConfig,
        });
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async updatePlatformList({ dispatch, state }, data) {
      const { reportType, selectedGraphs } = data;

      if (!reportType || !selectedGraphs) return;

      try {
        await services.apiCallForReporting("/report/graphConfig/update", data);
        //sync api widgets with category-list
        dispatch("syncWidgetsWithCategories", {
          reportType,
          apiWidgets: reportType === "Amazon" ? state.amazonReportData : state.reportData,
        });
      } catch (error) {
        console.error("error updating platform list", error);
      }
    },

    async updateDefaultReportAll({ commit }, data) {
      const path = data.isFacebookTable
        ? "updateDefaultReportForFbRealTime/all"
        : "updateDefaultReport/all";
      try {
        const response = services.apiCallForReporting(`/report/${path}`, data);
        return response;
      } catch (err) {
        console.error("Error fetching updatefbrealtime/all", err);
        captureException("Error fetching updatefbrealtime/all", err);
        return Promise.reject(err);
      }
    },

    async fetchLoadMoreTableData({ commit }, data) {
      const path = data?.isFacebookTable
        ? "updateDefaultReportForFbRealTime"
        : "updateDefaultReportForGAds";
      try {
        const response = await services.apiCallForReporting(`/report/${path}/loadMore`, data);
        return response;
      } catch (err) {
        console.error(err);
        captureException("Error fetching load more table data", err);
        return Promise.reject(err);
      }
    },
    async updateReport({ commit, state }, data) {
      try {
        const response = {};
        const responseOverallStats = await services.apiCallForReporting(
          "/report/updateDefaultReport/overallStats",
          data,
        );
        // get top 4
        const selectedGraphs = JSON.stringify(state?.categories);
        // need to get from mongoDB if not in localStorage?
        const top4SelectedGraphs = await services.apiCallForReporting("/report/graphConfig/top4", {
          selectedGraphs,
        });
        const top4SelectedGraphsData = {
          ...data,
          top4SelectedGraphs: top4SelectedGraphs?.data?.data || [],
        };
        const responseSelected = await services.apiCallForReporting(
          "/report/updateDefaultReport/selected",
          top4SelectedGraphsData,
        );

        const apiCall1 = services.apiCallForReporting(
          "/report/updateDefaultReportForFbRealTime/all",
          data,
        );
        const apiCall2 = services.apiCallForReporting("/report/updateDefaultReport/all", data);

        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        apiCall2
          .then((responseAll) => {
            const eventData1 = "reponse-all-success";
            const uniqueString1 = `async-update-ecomm-report-${shopId}`;

            EventBus.$emit(uniqueString1, eventData1);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        apiCall1
          .then((responseFbRealTime) => {
            const eventData = "reponse-fb-all-success";
            const uniqueString = `async-update-ecomm-fb-report-${shopId}`;

            EventBus.$emit(uniqueString, eventData);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        if (responseOverallStats.data.status === 200 && responseSelected.data.status === 200) {
          response.data = {
            status: 200,
            message: "Report updated successfully",
          };
          return response;
        } else {
          console.log("Either update all or updated selected api failed");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateShopifyReport({ commit }, data) {
      try {
        const response = await services.apiCallWithData("/report/updateDefaultShopifyReport", data);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async generateReport({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/report/generateDefaultReport/all",
          data,
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async generateReportForFbRealTime({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/report/generateDefaultReportForFbRealTime/all",
          data,
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async generateShopifyReport({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/report/generateDefaultShopifyReport",
          data,
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async shopifyReportSortData({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/report/updateDefaultShopifyReportWithSort",
          data,
        );
        commit("SET_shopifySortData", response);
        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async getDefaultEcommerceReport({ commit, getters, dispatch, state }, data) {
      const reportType = "Ecommerce";
      try {
        commit("set_loading", true);
        const response = await services.apiCallForReporting("/report/getDefaultReport", data);

        commit("set_default_response", true);
        if (response.data.status === 200) {
          const shopifyResponse = await services.apiCallWithData(
            "/report/getDefaultShopifyReport",
            data,
          );

          commit("set_shopify_response", true);
          let ecommerceReport = response.data.data.report || [];

          if (shopifyResponse.data.status === 200) {
            let shopifyReport = shopifyResponse.data.data.report || [];
            ecommerceReport = [...ecommerceReport, ...shopifyReport];

            ecommerceReport = ecommerceReport.reduce((result, item) => {
              if (item.name === "Metrics Widget") {
                //merge shopify and e-comm overall stats
                const existingItemIndex = result.findIndex(
                  (existingItem) => existingItem.name === "Metrics Widget",
                );
                if (existingItemIndex === -1) {
                  result.push(item);
                } else {
                  result[existingItemIndex].chart = {
                    ...result[existingItemIndex].chart,
                    ...item.chart,
                  };
                }
              } else {
                result.push(item);
              }

              return result;
            }, []);

            let index = ecommerceReport.findIndex((item) => item.name === "Metrics Widget");

            if (index !== -1) {
              const { totalRevenue, adSpend, totalOrders } = ecommerceReport[index].chart;

              if (totalRevenue && adSpend && totalOrders) {
                let splitAdSpend = adSpend.value.split(" ");
                let currency = "";
                if (splitAdSpend.length > 1) {
                  currency = splitAdSpend[0];
                }
                if (adSpend.value && getters.getCleanAndParseFloat(adSpend.value) > 0) {
                  let mainValue = 0,
                    fixedValue = 0,
                    previousValue = 0,
                    diff = 0,
                    comparedPercentage = 0;

                  mainValue =
                    getters.getCleanAndParseFloat(totalRevenue.value) /
                    getters.getCleanAndParseFloat(adSpend.value);

                  fixedValue = Number(mainValue.toFixed(2));
                  if (adSpend.previousValue) {
                    previousValue =
                      getters.getCleanAndParseFloat(totalRevenue.previousValue) /
                      getters.getCleanAndParseFloat(adSpend.previousValue);
                  } else {
                    previousValue = 0;
                  }
                  diff = mainValue - previousValue;
                  if (previousValue !== 0) {
                    comparedPercentage = (diff / previousValue) * 100;
                  } else {
                    comparedPercentage = (diff / 1) * 100;
                  }
                  commit("getFormattedPercent", comparedPercentage);
                  ecommerceReport[index].chart.ROI = {
                    value:
                      parseFloat(fixedValue.toFixed(2)).toLocaleString().length > 1
                        ? parseFloat(fixedValue.toFixed(2)).toLocaleString()
                        : fixedValue.toFixed(2),
                    compared: {
                      type: diff < 0 ? "dec" : diff > 0 ? "inc" : "same",
                      value: this.state.reporting.formattedPercent,
                    },
                  };
                } else {
                  ecommerceReport[index].chart.ROI = {
                    value: (0).toFixed(2),
                    compared: {
                      type: "zero",
                      value: "0%",
                    },
                  };
                }
                if (totalOrders.value && getters.getCleanAndParseFloat(totalOrders.value) > 0) {
                  let cppValue = 0,
                    cppFixedValue = 0,
                    cppPreviousValue = 0,
                    cppDiff = 0,
                    cppComparedPercentage = 0;
                  cppValue =
                    getters.getCleanAndParseFloat(adSpend.value) /
                    getters.getCleanAndParseFloat(totalOrders.value);

                  cppFixedValue = Number(cppValue.toFixed(2));
                  if (totalOrders.previousValue) {
                    cppPreviousValue =
                      getters.getCleanAndParseFloat(adSpend.previousValue) /
                      getters.getCleanAndParseFloat(totalOrders.previousValue);
                  } else {
                    cppPreviousValue = 0;
                  }
                  cppDiff = cppValue - cppPreviousValue;
                  if (cppPreviousValue !== 0) {
                    cppComparedPercentage = (cppDiff / cppPreviousValue) * 100;
                  } else {
                    cppComparedPercentage = (cppDiff / 1) * 100;
                  }
                  commit("getFormattedPercent", cppComparedPercentage);

                  ecommerceReport[index].chart.CostPerPurchase = {
                    value: currency
                      ? currency +
                        " " +
                        (parseFloat(cppFixedValue.toFixed(2)).toLocaleString().length > 1
                          ? parseFloat(cppFixedValue.toFixed(2)).toLocaleString()
                          : cppFixedValue.toFixed(2))
                      : parseFloat(cppFixedValue.toFixed(2)).toLocaleString().length > 1
                        ? parseFloat(cppFixedValue.toFixed(2)).toLocaleString()
                        : cppFixedValue.toFixed(2),
                    compared: {
                      type: cppDiff < 0 ? "dec" : "inc",
                      value: this.state.reporting.formattedPercent,
                    },
                  };
                } else {
                  ecommerceReport[index].chart.CostPerPurchase = {
                    value: currency ? currency + " " + (0).toFixed(2) : (0).toFixed(2),
                    compared: {
                      type: "zero",
                      value: "0%",
                    },
                  };
                }
              }
              let setup = this.state.reporting.reportingSetup;
              if (setup && (setup.weekFBReport || setup.weekGoogleAdsReport)) {
                ecommerceReport[index].chart.adSpend.showCard = true;
                ecommerceReport[index].chart.cpc.showCard = true;
                ecommerceReport[index].chart.ctr.showCard = true;
                ecommerceReport[index].chart.blendedRoas.showCard = true;
              } else {
                ecommerceReport[index].chart.adSpend.showCard = false;
                ecommerceReport[index].chart.cpc.showCard = false;
                ecommerceReport[index].chart.ctr.showCard = false;
                ecommerceReport[index].chart.blendedRoas.showCard = false;
              }
              if (setup && setup.weekGoogleAnalyticsReport) {
                ecommerceReport[index].chart.conversionRate.showCard = true;
              } else {
                ecommerceReport[index].chart.conversionRate.showCard = false;
              }
              ecommerceReport[index].chart.totalOrders.showCard = true;
              ecommerceReport[index].chart.totalRevenue.showCard = true;
              ecommerceReport[index].chart.AOV.showCard = true;
              ecommerceReport[index].chart.ROI.showCard = true;
              ecommerceReport[index].chart.CostPerPurchase.showCard = true;
              ecommerceReport[index].chart["Return Revenue"].showCard = true;
            }

            let categoryIndex = ecommerceReport.findIndex((item) => item.name === "Categories");

            const reportUpdatedData = setCategoriesWidget(ecommerceReport, categoryIndex);

            ecommerceReport = reportUpdatedData;

            //ordering
            const flatArray = getWidgetListInOrder(state.categories);

            let finalReports = flatArray.map((widget, index) => {
              const { platformId, displayName, platformName } = widget;

              const matching = ecommerceReport?.find((report) => {
                if (platformId) {
                  if (report.displayName === displayName && report.platformId === platformId) {
                    report.placement = index;
                    report.platformName = platformName;
                    return report;
                  }
                } else {
                  if (report.displayName === displayName) {
                    report.placement = index;
                    report.platformName = platformName;
                    return report;
                  }
                }
              });
              return matching;
            });

            //remove null/undefined entries
            finalReports = finalReports.filter((el) => el != null);

            ecommerceReport = finalReports;
            ecommerceReport.sort((a, b) => a.placement - b.placement);

            //sync api widgets with category-list
            dispatch("syncWidgetsWithCategories", {
              reportType,
              apiWidgets: ecommerceReport,
            });

            // Get unique platformName values from reportDetails
            const uniqueNames = new Set();
            ecommerceReport?.forEach((chart) => {
              uniqueNames.add(chart.platformName);
            });

            commit("set_unique_platform_names", uniqueNames);

            commit("set_reportData", ecommerceReport);
            return ecommerceReport;
          } else {
            console.error("Shopify API error:", shopifyResponse.data.message);
          }
        } else if (response.data.status === 400) {
          commit("set_reportData", []);
        } else {
          commit("set_reportData", []);
        }
      } catch (error) {
        console.error("API call failed:", error);
      } finally {
        commit("set_loading", false);
      }
    },

    async getDefaultShopifyReport({ commit }, data) {
      commit("set_loading", true);
      const response = await services.apiCallWithData("/report/getDefaultShopifyReport", data);
      commit("set_loading", false);
      if (response.data.status === 200) {
        if (response.data.data.report) {
          commit("set_shopifyReportData", response.data.data.report);
        } else {
          commit("set_shopifyReportData", []);
        }
      } else if (response.data.status === 400) {
        commit("set_shopifyReportData", []);
      } else {
        commit("set_shopifyReportData", []);
      }
    },
    async saveGoogleAdsId({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/google/v1/saveGoogleAdsId", data);
        return response;
      } catch (error) {
        console.error(error);
        captureException("Error in saving google ads id", error);
        return Promise.reject(error);
      }
    },
    async getGoogleAdsCustomerList({ commit }) {
      try {
        const response = await services.apiCallForReporting(
          "/google/v1/getListAccessibleCustomers",
        );
        if (response.data.status === 200) {
          let accounts = response.data.data;
          await accounts.map((account) => {
            account.checked = false;
            return account;
          });
          commit("set_googleAdsCustomerList", accounts);
        } else if (response.data.status === 400) {
          commit("setGAdsError", response.data.message);
          commit("set_googleAdsCustomerList", []);
        } else {
          commit("setGAdsError", "Something Went Wrong");
          commit("set_googleAdsCustomerList", []);
        }
      } catch (error) {
        console.error("getGoogleAdsCustomerList error", error);
        captureException("Error in fetching gooel ads customer list", error);
        commit("set_googleAdsCustomerList", []);
        return Promise.reject(error);
      }
    },
    async checkReportingSetup({ commit }) {
      try {
        const response = await services.apiCallForReporting("/state/checkSetup");
        if (response.data.status === 200) {
          commit("set_setupStatus", response.data.data);
        } else if (response.data.status === 400) {
          commit("set_setupStatusError", response.data.message);
          commit("set_setupStatus", {});
        } else {
          commit("set_setupStatusError", "Something Went Wrong");
          commit("set_setupStatus", {});
        }
        // return response;
      } catch (error) {
        console.error(error);
      }
    },
    async facebookReauthenticate({ commit }) {
      try {
        const response = await services.apiCallForReporting("/facebook/reauthenticate");
        if (response.data.status === 200) {
          commit("set_facebookReauthenticate", response.data.data);
        } else if (response.data.status === 400) {
          commit("set_facebookReauthenticateError", response.data.message);
          commit("set_facebookReauthenticate", {});
        } else {
          commit("set_facebookReauthenticateError", "Something Went Wrong");
          commit("set_facebookReauthenticate", {});
        }
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async saveGAInfo({ commit }, data) {
      try {
        const response = await services.apiCallForReporting(
          "/google/v1/googleAnalyticsSetup",
          data,
        );
        return response;
      } catch (error) {
        console.error(error);
        captureException("Error in saving Google Analytics info", error);
        return Promise.reject(error);
      }
    },
    async getWebPropertyList({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/google/v1/getAllWebProperty", data);
        if (response.data.status === 200) {
          commit("setGAWebProperty", response.data.data.webproperties);
        } else if (response.data.status === 400) {
          commit("setGAError", response.data.data.message);
          commit("setGAWebProperty", []);
        } else {
          commit("setGAError", "Something Went Wrong");
          commit("setGAWebProperty", []);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getGoogleAnalyticsAccounts({ commit }) {
      try {
        const response = await services.apiCallForReporting("/google/v1/getAllAnalyticsAccount");
        if (response.data.status === 200) {
          commit("setGAAccounts", response.data.data.accounts);
        } else if (response.data.status === 400) {
          commit("setGAError", response.data.data.message);
          commit("setGAAccounts", []);
        } else {
          commit("setGAError", "Something Went Wrong");
          commit("setGAAccounts", []);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveSelectFbAdsAccount({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/facebook/v1/selectAdsAccount", data);
        return response;
      } catch (err) {
        captureException("Error saving selected facebook ads account", err);
        return Promise.reject(err);
      }
    },
    async getFbAdsAccount({ commit }) {
      try {
        const response = await services.apiCallForReporting("/facebook/getAdAccounts");
        if (response.data.status === 200) {
          let accounts = response.data.data.accounts;
          await accounts.map((account) => {
            account.checked = false;
            return account;
          });
          commit("setFbIdName", accounts);
        } else if (response.data.status === 400) {
          commit("setFbError", response.data.message);
          commit("setFbIdName", []);
        } else {
          commit("setFbError", "Something Went Wrong");
          commit("setFbIdName", []);
        }
      } catch (err) {
        console.log("getFbAdsAccount", err);
        commit("setFbError", "Error in fetching Ad Accounts.");
        commit("setFbIdName", []);
      }
    },
    async maxAdsAccount({ commit }, data) {
      try {
        const response = await services.getApiCallForReporting(
          `/platform/getMaxAdAccounts/platformType?platformType=${data.platformType}&processType=${data.processType}`,
        );
        if (response.data.status === 200) {
          commit("set_maxAdsAccount", response.data.data);
        } else {
          commit("set_maxAdsAccount", { max: 10, available: 10 });
        }
        return response;
      } catch (err) {
        console.log(err);
        captureException("Error getting maxAdsAccount", err);
        return Promise.reject(err);
      }
    },

    async fetchdAccountsForPlatform({ dispatch, commit }, data) {
      const { routeParam, platformType } = data;
      commit("set_isFetchingAdAccountsForPlatform", true);
      if (routeParam === "fbcatalog" || routeParam === "gmc") {
        await dispatch(
          "integration/getShopifyPlatformList",
          {
            platformType: platformType,
          },
          { root: true },
        );
      } else {
        await dispatch(
          "integration/getPlatformList",
          {
            platformType: platformType,
          },
          { root: true },
        );
      }

      if (routeParam === "googleads") await dispatch("getGoogleAdsCustomerList");
      if (routeParam === "fbads") await dispatch("getFbAdsAccount");

      commit("set_isFetchingAdAccountsForPlatform", false);
    },

    async installGoogleAnalytics() {
      try {
        const organizationId = encryptor.decrypt(localStorage.getItem("organizationId"));
        const response = await services.getApiCallForReporting(
          `/google/analyticsInstall?organizationId=${organizationId}`,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    },
    async installGoogleAds() {
      try {
        const organizationId = encryptor.decrypt(localStorage.getItem("organizationId"));
        const response = await services.getApiCallForReporting(
          `/google/adsInstall?organizationId=${organizationId}`,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    },
    async installFacebook() {
      try {
        const organizationId = encryptor.decrypt(localStorage.getItem("organizationId"));
        const response = await services.getApiCallForReporting(
          `/facebook/adsInstall?organizationId=${organizationId}`,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    },
    async installAmazonAds() {
      try {
        const organizationId = encryptor.decrypt(localStorage.getItem("organizationId"));
        const response = await services.getApiCallForReporting(
          `/amazonads/install?organizationId=${organizationId}`,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    },
    async installAmazonSeller() {
      try {
        const organizationId = encryptor.decrypt(localStorage.getItem("organizationId"));
        const response = await services.getApiCallForReporting(
          `/amazonseller/install?organizationId=${organizationId}`,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    },
    async getAmazonProfiles({ commit }) {
      const response = await services.getApiCallForReporting("/amazonads/getProfiles");
      if (response.data.status === 200) {
        commit("setProfileIdName", response.data.data.profiles);
      } else if (response.data.status === 400) {
        commit("setProfileError", response.data.message);
        commit("setProfileIdName", []);
      } else {
        commit("setProfileError", "Something Went Wrong");
        commit("setProfileIdName", []);
      }
    },
    async getAmazonSellerProfiles({ commit }) {
      const response = await services.getApiCallForReporting("/amazonseller/getProfiles");
      if (response.data.status === 200) {
        commit("setProfileIdSellerName", response.data.data.profiles.payload);
      } else if (response.data.status === 400) {
        commit("setProfileSellerError", response.data.message);
        commit("setProfileIdSellerName", []);
      } else {
        commit("setProfileSellerError", "Something Went Wrong");
        commit("setProfileIdSellerName", []);
      }
    },
    async saveSelectAmazonAdsProfile({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/amazonads/saveProfile", data);
        return response;
      } catch (err) {
        console.log(err);
        captureException("Error saving selected amazon ads profile", err);
        return Promise.reject(err);
      }
    },
    async saveSelectAmazonSellerProfile({ commit }, data) {
      try {
        const response = await services.apiCallForReporting("/amazonseller/saveProfile", data);
        return response;
      } catch (err) {
        console.log(err);
        captureException("Error saving selected amazon seller profile", err);
        return Promise.reject(err);
      }
    },
    async createState({ commit, dispatch }) {
      let shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      let organizationId = encryptor.decrypt(localStorage.getItem("organizationId"));
      let domain;
      const apiresponse = await services.apiCallWithGetData(`/shop/${organizationId}/${shopId}`);
      if (apiresponse.data.status == 200 && apiresponse.data.data.shop) {
        domain = "https://" + apiresponse.data.data.shop.shopify[0].domain;
        const response = await services.apiForReportingWithoutHeader("/state/createState", {
          shopId: shopId,
          domain: domain,
        });
        if (response.data.status === 200) {
          commit("set_stateDetails", response.data.data.details);
        } else if (response.data.status === 400) {
          commit("set_error", response.data.message);
          commit("set_stateDetails", {});
        } else {
          commit("Something Went Wrong");
          commit("set_stateDetails", {});
        }
      }
    },
    async shareSchedule({ commit }, data) {
      try {
        let responses;
        if (data.processType == "download") {
          responses = await services.apiCallForReportingDownload("/schedule/shareSchedule", data);
        } else {
          responses = await services.apiCallForReporting("/schedule/shareSchedule", data);
        }
        return responses;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
