//maintain position of widgets
const googleWidgetList = [
  "Search Overview",
  "Performance Max Overview",
  "Pmax Listing Group",
  "Top Performing Keywords",
  "Ad Quality",
  "Video Overview",
  "Video Views",
  "Shopping Overview",
  "Shopping Listing Group",
];

const facebookWidgetList = ["Campaign", "Adset", "Creative"];

const shortenWidgets = [
  "FBAdsPlatform",
  "Campaign",
  "Adset",
  "Creative",
  "GoogleAdsPlatform",
  "Search Overview",
  "Top Performing Keywords",
  "Video Views",
];
const shortenWidgetColumnList = [
  {
    name: "FBAdsPlatform",
    headers: [
      "Platforms",
      "Spends",
      "Purchases",
      "CPP (Cost Per Purchase)",
      "Revenue",
      "ROAS (Return On Ad Spend)",
    ],
    position: [0, 1, 7, 9, 15, 17],
  },
  {
    name: "Campaign",
    headers: [
      "Campaign",
      "Spends",
      "Purchases",
      "CPP (Cost Per Purchase)",
      "Revenue",
      "ROAS (Return On Ad Spend)",
    ],
    position: [0, 1, 7, 9, 15, 17],
  },
  {
    name: "Adset",
    headers: [
      "Ad Set",
      "Spends",
      "Purchases",
      "CPP (Cost Per Purchase)",
      "Revenue",
      "ROAS (Return On Ad Spend)",
    ],
    position: [0, 2, 8, 10, 16, 18],
  },
  {
    name: "Creative",
    headers: [
      "Ad Name",
      "Spends",
      "CPP (Cost Per Purchase)",
      "CTR (Overall)",
      "Revenue",
      "ROAS (Return On Ad Spend)",
    ],
    position: [0, 5, 13, 16, 19, 21],
  },
  {
    name: "GoogleAdsPlatform",
    headers: [
      "Account",
      "Cost",
      "Purchases",
      "CPP (Cost Per Purchase)",
      "Revenue",
      "ROAS (Return On Ad Spend)",
    ],
    position: [0, 1, 4, 5, 9, 11],
  },
  {
    name: "Search Overview",
    headers: [
      "Campaigns",
      "Cost",
      "Purchases",
      "CPP (Cost Per Purchase)",
      "Revenue",
      "ROAS (Return On Ad Spend)",
    ],
    position: [0, 1, 4, 5, 7, 8],
  },
  {
    name: "Top Performing Keywords",
    headers: [
      "Keyword",
      "Cost",
      "Conversions",
      "Conversion Value",
      "CTR (Click Through Rate)",
    ],
    position: [0, 3, 6, 7, 11],
  },
  {
    name: "Video Views",
    headers: ["Video", "Campaign", "Cost", "Views", "Avg. CPV", "View Rate"],
    position: [0, 1, 3, 4, 5, 6],
  },
];

export {
  facebookWidgetList,
  googleWidgetList,
  shortenWidgets,
  shortenWidgetColumnList,
};
