import services from "../../services/apiServices";
import encryptor from "../plugins/encryption";
import moment from "moment";

export default {
  namespaced: true,

  state: {
    shopAccessTokenStatus: false,
    error: "",
    free_error: "",
    subscription: {},
    exploreAudienceAccess: false,
  },
  mutations: {
    set_shopAccessTokenStatus(state, data) {
      state.shopAccessTokenStatus = data;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_free_error(state, data) {
      state.free_error = data;
    },
    set_subscription(state, data) {
      state.subscription = data;
    },
    set_exploreAudienceAccess(state, data) {
      state.exploreAudienceAccess = data;
    },
  },
  actions: {
    async getLibraryAccessStatus({ commit }) {
      const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      const response = await services.apiCallWithGetData(
        `/subscribe/getLibraryAccessStatus?shop=${shopId}`
      );
      if (response.data.status == 200 && response.data.data) {
        if (response.data.data.accessStatus) {
          commit("set_exploreAudienceAccess", response.data.data.accessStatus);
        } else {
          commit("set_exploreAudienceAccess", false);
        }
      } else {
        commit("set_exploreAudienceAccess", false);
      }
    },
    async createSubscriptionToken({ commit }, data) {
      const response = await services.apiCallWithData(
        "/shop/createShopToken",
        data
      );
      if (
        response.data.status == 200 &&
        response.data.data &&
        response.data.data.token
      ) {
        localStorage.setItem(
          "x-shop-access-token",
          encryptor.encrypt(response.data.data.token)
        );
        commit("set_shopAccessTokenStatus", true);
        commit("set_error", "");
      } else if (response.data.status == 400) {
        commit("set_shopAccessTokenStatus", false);
        commit("set_error", response.data.message);
      } else {
        commit("set_error", "Something went wrong.");
      }
    },
    async getActiveSubscription({ commit }, data) {
      const response = await services.apiCallWithData(
        "/subscribe/getActiveSubscription",
        data
      );
      if (response.data.data && response.data.status == 200) {
        let planValidity = response.data.data.trialDays;
        let subscribeDate = moment(response.data.data.subscriptionDate).format(
          "YYYY-MM-DD"
        );
        let diffDay = moment().diff(subscribeDate, "days");
        if (diffDay < 14 && diffDay >= 0 && diffDay < planValidity) {
          response.data.data.trialRemains = planValidity - diffDay;
        } else {
          response.data.data.trialRemains = 0;
        }
        commit("set_subscription", response.data.data);
        commit("set_error", "");
      } else if (response.data.status == 400) {
        commit("set_subscription", {});
        commit("set_error", response.data.message);
      } else {
        commit("set_subscription", {});
        commit("set_error", "Something went wrong.");
      }
    },
    // FREE SUBSCRIPTION
    async subscribeFreePlan({ commit }, data) {
      const response = await services.apiCallWithData(
        "/subscribe/subscribeFreePlan",
        data
      );
      if (response.data.status == 200) {
        commit("set_free_error", "");
      } else if (response.data.status == 400) {
        commit("set_free_error", response.data.message);
      } else {
        commit("set_free_error", "Something went wrong.");
      }
    },
    // FREE SUBSCRIPTION END
  },
};
