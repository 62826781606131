import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import userManagement from "./userManagement";
import onboarding from "./onboarding";
import storeSetup from "./storeSetup";
import myAccount from "./myAccount";
import audience from "./audience";
import settings from "./settings";
import pricingPlan from "./pricingPlan";
import subscriptionPlan from "./subscriptionPlan";
import catalog from "./catalog";
import walkthrough from "./walkthrough";
import panelHeader from "./panelHeader";
import config from "./config";
import connecting from "./connecting";
import reporting from "./reporting";
import fbConnecting from "./fbConnecting";
import dashboardTab from "./dashboardTab";
import integration from "./integration";
import aiSummary from "./ai-summary";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    userManagement,
    onboarding,
    storeSetup,
    myAccount,
    audience,
    settings,
    pricingPlan,
    subscriptionPlan,
    catalog,
    walkthrough,
    panelHeader,
    config,
    connecting,
    reporting,
    fbConnecting,
    dashboardTab,
    integration,
    aiSummary,
  },
});
