import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const colorsLight = {
  primary: "#000000",
  primary1: "#ffffff",
  secondary: "#542CC2",
  secondary1: "#8231C2",
  background: "#f1f2f6",
  background1: "#FFFFFF",
  background2: "#ffffff",
  background3: "#f1f2f6",
  background4: "#ffffff",
  background5: "#F4F4F6",
  background6: "#FAFBFB",
  tooltipbg: "#202029",
  rangeSlider: "#DCDBDC",
  border1: "#dfe0eb",
  border2: "#979797",
  reportBorder: "#eee",
  reportTableHeader: "#F5F5F5",
  chip: "#e0e0e0",
  scroll: "#c3c4cf",
  darken1: "#F2F2F2",
  darken2: "#F2F2F2",
  lighten1: "#e2e2e2",
  error: "#E44817",
};

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: colorsLight,
    },
    defaultFont: {
      family: "DM Sans", // Specify your custom font family
    },
  },
});
