import encryptor from "@/plugins/encryption";
import isEmpty from "lodash/isEmpty";
import services from "../../services/apiServices";
export default {
  namespaced: true,
  state: {
    error: null,
    confirmationUrl: null,
    planConfirmModal: false,
    planNameId: {},
    loading: false,
    pageLoading: false,
    orderVerifyModal: false,
    planType: false,
    featurePlans: [],
    recomendData: {},
    upcomingPlan: {},
  },
  getters: {
    getFeaturePlans(state) {
      return state.featurePlans;
    },
  },

  mutations: {
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_URL(state, url) {
      state.confirmationUrl = url;
    },
    setPlanConfirmModal(state, data) {
      state.planConfirmModal = data;
    },
    setPlanNameId(state, data) {
      state.planNameId = data;
    },
    set_loading(state, data) {
      state.loading = data;
    },
    setPageLoading(state, data) {
      state.pageLoading = data;
    },
    setOrderVerifyModal(state, data) {
      state.orderVerifyModal = data;
    },
    setPlanType(state, data) {
      state.planType = data;
    },
    setFeaturePlans(state, data) {
      state.featurePlans = data;
    },
    setRecomendData(state, data) {
      state.recomendData = data;
    },
    setUpcomingPlan(state, data) {
      state.upcomingPlan = data;
    },
  },
  actions: {
    async getUpcomingPlan({ commit }) {
      let shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      const response = await services.apiCallWithGetData(
        `/subscribe/upcomingPlan/${shopId}`
      );
      if (response.data.status == 200) {
        if (!isEmpty(response.data.data)) {
          commit("setUpcomingPlan", response.data.data);
        } else {
          commit("setUpcomingPlan", {});
        }
      } else {
        commit("setUpcomingPlan", {});
      }
    },
    async cancelSubscription({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/subscribe/cancellation",
          data
        );
        return response;
      } catch (err) {}
    },
    async getPlans({ commit }, data) {
      const response = await services.apiCallWithGetData(`/subscribe/${data}`);
      if (response.data.status == 200) {
        commit("setFeaturePlans", response.data.data);
      } else {
        commit("setFeaturePlans", []);
      }
    },
    async getRecomendedSubscription({ commit, data }) {
      let shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      const response = await services.apiCallWithData(
        "/subscribe/getRecomendedSubscription",
        { shopId: shopId }
      );
      if (response.data.status == 200) {
        commit("setRecomendData", response.data.data);
        commit(
          "setPlanType",
          response.data.data.planType == "Annually" ? true : false
        );
      } else {
        commit("setRecomendData", {});
      }
    },
    async checkSynchronizationStatus({ commit, data }) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        const response = await services.apiCallWithData(
          "/catalogue/checkSynchronizationStatus",
          { shopId: shopId }
        );
        return response;
      } catch (err) {}
    },
    async subscribePlan({ commit }, data) {
      commit("set_loading", true);
      const response = await services.apiCallWithData("/subscribe", data);
      commit("set_loading", false);
      if (response.data.status == 200) {
        commit("SET_URL", response.data.data);
      } else if (response.data.status == 400) {
        commit("SET_ERROR", response.data.message);
      } else {
        commit("SET_ERROR", "Unable To Process Your Request Right Now");
      }
    },
    async UpgradeSubscribePlan({ commit }, data) {
      commit("set_loading", true);
      const response = await services.apiCallWithData(
        "/subscribe/update",
        data
      );
      commit("set_loading", false);
      if (response.data.status == 200) {
        commit("SET_URL", response.data.data);
      } else if (response.data.status == 400) {
        commit("SET_ERROR", response.data.message);
      } else {
        commit("SET_ERROR", "Unable To Process Your Request Right Now");
      }
    },
  },
};
