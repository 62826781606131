import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";
import isEmpty from "lodash/isEmpty";

export default {
  namespaced: true,

  state: {
    loading: false,
    loading1: false,
    catalogueDetails: {},
    productPage: 1,
    collectionPage: 1,
    tabSelected: 0,
    allProducts: [],
    allCollections: [],
    allProductsId: [],
    allCollectionsId: [],
    productCount: 0,
    collectionCount: 0,
    limit: 10,
    editIndustryModal: false,
    editCollectionModal: false,
    editProductModal: false,
    editSelectedModal: false,
    collectionDetailsForEdit: {},
    productDetailsForEdit: {},
    detailsForBulkEdit: {},
    affectedProducts: [],
    error: "",
    tooltipStep: 1,
    allProductData: {},
    allCollectionData: {},
    allProductIdData: [],
    allCollectionIdData: [],
    catwalkthroughModal: false,
    catalogOverrideModal: false,
    selectedRowCountForProduct: "10",
    selectedRowCountForCollection: "10",
    productSearch: "",
    collectionSearch: "",
    basicDetails: {},
    media: [],
    googleProductCategory: {},
    facebookProductCategory: {},
    customAttributes: {},
    detailedProductCharacteristics: {},
    productLabels: {},
    adLabels: {},
    shippingLabels: {},
    variants: [],
    productIdToEdit: null,
    itemBeingEdited: {},
  },
  mutations: {
    set_selectedRowCountForProduct(state, data) {
      state.selectedRowCountForProduct = data;
    },
    set_selectedRowCountForCollection(state, data) {
      state.selectedRowCountForCollection = data;
    },
    set_catalogOverrideModal(state, data) {
      state.catalogOverrideModal = data;
    },
    set_loading(state, data) {
      state.loading = data;
    },
    set_loading1(state, data) {
      state.loading1 = data;
    },
    set_catalogueDetails(state, data) {
      state.catalogueDetails = data;
    },
    set_productPage(state, data) {
      state.productPage = data;
    },
    set_collectionPage(state, data) {
      state.collectionPage = data;
    },
    set_productSearch(state, data) {
      state.productSearch = data;
    },
    set_collectionSearch(state, data) {
      state.collectionSearch = data;
    },
    set_tabSelected(state, data) {
      state.tabSelected = data;
    },
    set_allProducts(state, data) {
      state.allProducts = data;
    },
    set_allCollections(state, data) {
      state.allCollections = data;
    },
    set_allProductsId(state, data) {
      state.allProductsId = data;
    },
    set_allCollectionsId(state, data) {
      state.allCollectionsId = data;
    },
    set_productCount(state, data) {
      let floor = data / state.selectedRowCountForProduct;
      state.productCount = Math.ceil(floor);
    },
    set_collectionCount(state, data) {
      let floor = data / state.selectedRowCountForCollection;
      state.collectionCount = Math.ceil(floor);
    },
    set_editCollectionModal(state, data) {
      state.editCollectionModal = data;
    },
    set_editProductModal(state, data) {
      state.editProductModal = data;
    },
    set_editSelectedModal(state, data) {
      state.editSelectedModal = data;
    },
    set_editIndustryModal(state, data) {
      state.editIndustryModal = data;
    },
    set_collectionDetailsForEdit(state, data) {
      state.collectionDetailsForEdit = data;
    },
    set_productDetailsForEdit(state, data) {
      state.productDetailsForEdit = data;
    },
    set_detailsForBulkEdit(state, data) {
      state.detailsForBulkEdit = data;
    },
    set_affectedProducts(state, data) {
      state.affectedProducts = data;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_tooltipStep(state, data) {
      state.tooltipStep = data;
    },
    set_allProductData(state, data) {
      state.allProductData = data;
    },
    set_allCollectionData(state, data) {
      state.allCollectionData = data;
    },
    set_allProductIdData(state, data) {
      state.allProductIdData = data;
    },
    set_allCollectionIdData(state, data) {
      state.allCollectionIdData = data;
    },
    set_catwalkthroughModal(state, data) {
      state.catwalkthroughModal = data;
    },
    set_productIdToEdit(state, data) {
      state.productIdToEdit = data;
    },
    set_basicDetails(state, data) {
      state.basicDetails = data;
    },
    set_media(state, data) {
      state.media = data;
    },
    set_googleProductCategory(state, data) {
      state.googleProductCategory = data;
    },
    set_facebookProductCategory(state, data) {
      state.facebookProductCategory = data;
    },
    set_customAttributes(state, data) {
      state.customAttributes = data;
    },
    set_detailedProductCharacteristics(state, data) {
      state.detailedProductCharacteristics = data;
    },
    set_productLabels(state, data) {
      state.productLabels = data;
    },
    set_adLabels(state, data) {
      state.adLabels = data;
    },
    set_shippingLabels(state, data) {
      state.shippingLabels = data;
    },
    set_variants(state, data) {
      state.variants = data;
    },
    set_itemBeingEdited(state, data) {
      state.itemBeingEdited = data;
    },
  },
  actions: {
    async uploadImage({ commit }, data) {
      try {
        const response = await services.uploadFile("/api/upload/", data);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getCollectionProducts({ commit }, data) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        data.shopId = shopId;
        const response = await services.apiCallWithData(
          "/catalogue/getCollectionProducts",
          data
        );
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status == 200 &&
          response.data.data &&
          !isEmpty(response.data.data)
        ) {
          commit("set_affectedProducts", response.data.data.products);
        } else {
          commit("set_affectedProducts", []);
        }
      } catch (err) {
        commit("set_affectedProducts", []);
      }
    },
    async importOverride({ commit }) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        const response = await services.apiCallWithData(
          "/catalogue/synchronise",
          { shopId: shopId }
        );
        return response;
      } catch (err) {}
    },
    async importTodayOverride({ commit }) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        const response = await services.apiCallWithData(
          "/catalogue/synchroniseToday",
          { shopId: shopId }
        );
        return response;
      } catch (err) {}
    },
    async importSync({ commit }) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        commit("set_loading", true);
        const response = await services.apiCallWithData(
          "/catalogue/importSynchronise",
          { shopId: shopId }
        );
        commit("set_loading", false);
        return response;
      } catch (err) {
        commit("set_loading", false);
      }
    },
    async getCatalogueDetails({ commit }) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        commit("set_loading", true);
        const response = await services.apiCallWithData(
          "/catalogue/getCatalogueDetails",
          { shopId: shopId }
        );
        commit("set_loading", false);
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status == 200 &&
          response.data.data &&
          !isEmpty(response.data.data)
        ) {
          commit("set_catalogueDetails", response.data.data);
          commit("set_error", null);
        } else {
          commit("set_catalogueDetails", {});
          commit("set_error", response.data.message);
        }
      } catch (err) {
        commit("set_loading", false);
        commit("set_catalogueDetails", {});
        commit("set_error", null);
      }
    },
    async getProducts({ commit }, data) {
      const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      data.shopId = shopId;
      commit("set_loading1", true);
      const response = await services.apiCallWithData(
        "/catalogue/getProducts",
        data
      );
      commit("set_loading1", false);
      if (
        response.data.status == 200 &&
        response.data.data &&
        response.data.data.products &&
        response.data.data.products.length &&
        response.data.data.count &&
        response.data.data.count.length &&
        response.data.data.count[0] &&
        response.data.data.count[0].count
      ) {
        commit("set_allProducts", response.data.data.products);
        commit("set_productCount", response.data.data.count[0].count);
        commit("set_allProductData", response.data.data);
      } else {
        commit("set_allProducts", []);
        commit("set_productCount", 0);
        commit("set_allProductData", {});
      }
    },
    async getCollections({ commit }, data) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        data.shopId = shopId;
        commit("set_loading1", true);
        const response = await services.apiCallWithData(
          "/catalogue/getCollections",
          data
        );
        commit("set_loading1", false);
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status == 200 &&
          response.data.data &&
          response.data.data.collections &&
          response.data.data.collections.length &&
          response.data.data.count &&
          response.data.data.count.length &&
          response.data.data.count[0] &&
          response.data.data.count[0].count
        ) {
          commit("set_allCollections", response.data.data.collections);
          commit("set_collectionCount", response.data.data.count[0].count);
          commit("set_allCollectionData", response.data.data);
        } else {
          commit("set_allCollections", []);
          commit("set_collectionCount", 0);
          commit("set_allCollectionData", {});
        }
      } catch (err) {
        commit("set_loading", false);
        commit("set_allCollections", []);
        commit("set_collectionCount", 0);
        commit("set_allCollectionData", {});
      }
    },
    async getAllProducts({ commit }, data) {
      const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      data.shopId = shopId;
      commit("set_loading1", true);
      const response = await services.apiCallWithData(
        "/catalogue/getAllProducts",
        data
      );
      commit("set_loading1", false);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status == 200 &&
        response.data.data.length > 0
      ) {
        commit("set_allProductsId", response.data.data);
        commit("set_allProductIdData", response.data.data);
      } else {
        commit("set_allProductsId", []);
        commit("set_allProductIdData", {});
      }
    },
    async getAllCollections({ commit }, data) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        data.shopId = shopId;
        commit("set_loading1", true);
        const response = await services.apiCallWithData(
          "/catalogue/getAllCollections",
          data
        );
        commit("set_loading1", false);
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status == 200 &&
          response.data.data.length > 0
        ) {
          commit("set_allCollectionsId", response.data.data);
          commit("set_allCollectionIdData", response.data.data);
        } else {
          commit("set_allCollectionsId", []);
          commit("set_allCollectionIdData", {});
        }
      } catch (err) {
        commit("set_loading", false);
        commit("set_allCollectionsId", []);
        commit("set_allCollectionIdData", {});
      }
    },
    async editProductDetails({ commit }, data) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        data.shopId = shopId;
        const response = await services.apiCallWithData(
          "/catalogue/catalogueDetails/productUpdate",
          data
        );
        return response;
      } catch (err) {}
    },
    async editCollectionDetails({ commit }, data) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        data.shopId = shopId;
        const response = await services.apiCallWithData(
          "/catalogue/catalogueDetails/update",
          data
        );
        return response;
      } catch (err) {}
    },
    async updateBulkProducts({ commit }, data) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        data.shopId = shopId;
        const response = await services.apiCallWithData(
          "/catalogue/bulkUpdateProducts",
          data
        );
        return response;
      } catch (err) {}
    },
    async getProductDetails({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/catalogue/getProductsDetails",
          data
        );
        const { data: details } = response.data;
        commit("set_productIdToEdit", details.id);
        const basicDetails = {
          productGroupId: details.id,
          productTitle: details.title,
          description: details.body_html,
          productHighlights: details.productHighlight,
          productType: details.product_type,
          vendor: details.vendor,
          pause: details.pauseFeed,
        };
        commit("set_basicDetails", basicDetails);
        const mediaWithOverlay = details.images.map((image) => ({
          ...image,
          showOverlay: !image.selected,
        }));
        commit("set_media", mediaWithOverlay);
        const googleProductCategory = {
          category: details.googleProductCategoryFeed,
        };
        commit("set_googleProductCategory", googleProductCategory);
        const facebookProductCategory = {
          category: details.fbProductCategoryFeed,
        };
        commit("set_facebookProductCategory", facebookProductCategory);
        const customAttributes = {
          color: details.colorFeed,
          size: details.sizeFeed,
          material: details.materialFeed,
          pattern: details.patternFeed,
          energyEfficiencyClass: details.energyEfficiencyClassFeed,
          maxEnergyEfficiencyClass: details.maxEnergyEfficiencyClassFeed,
          minEnergyEfficiencyClass: details.minEnergyEfficiencyClassFeed,
          productHeight: details.productHeight,
          productHeightUnit: details.productHeightUnit,
          productLength: details.productLength,
          productLengthUnit: details.productLengthUnit,
          productWidth: details.productWidth,
          productWidthUnit: details.productWidthUnit,
        };
        commit("set_customAttributes", customAttributes);
        const detailedProductCharacteristics = {
          condition: details.conditionFeed,
          ageGroup: details.ageGroupFeed,
          gender: details.genderFeed,
          sizeSystem: details.sizeSystemFeed,
          sizeType: details.sizeTypeFeed,
          unitPricingBaseMeasure: details.unitPricingBaseMeasure,
          unitPricingBaseMeasureUnit: details.unitPricingBaseMeasureUnit,
          unitPricingMeasure: details.unitPricingMeasure,
          unitPricingMeasureUnit: details.unitPricingMeasureUnit,
          adultOriented: details.adultOrientedFeed,
        };
        commit(
          "set_detailedProductCharacteristics",
          detailedProductCharacteristics
        );
        const productLabels = {
          customLabel0: details.customLabelFeed0,
          customLabel1: details.customLabelFeed1,
          customLabel2: details.customLabelFeed2,
          customLabel3: details.customLabelFeed3,
          customLabel4: details.customLabelFeed4,
        };
        commit("set_productLabels", productLabels);
        const adLabels = {
          adsGrouping: details.adsGroupingFeed,
          adsLabel: details.adsLabelFeed,
          displayAdsId: details.displayAdsIdFeed,
          displayAdsLink: details.displayAdsLinkFeed,
          displayAdsSimilarId: details.displayAdsSimilarIdFeed,
          displayAdsTitle: details.displayAdsTitle,
          displayAdsValue: details.displayAdsValueFeed,
          promotionId: details.promotionIdFeed,
        };
        commit("set_adLabels", adLabels);
        const shippingLabels = {
          shippingLabel: details.shippingLabelFeed,
          returnPolicy: details.returnPolicyFeed,
          shipping: details.shippingFeed,
          shippingHeight: details.shippingHeight,
          shippingHeightUnit: details.shippingHeightUnit,
          shippingLength: details.shippingLength,
          shippingLengthUnit: details.shippingLengthUnit,
          shippingWidth: details.shippingWidth,
          shippingWidthUnit: details.shippingWidthUnit,
          maxHandlingTime: details.maxHandlingTimeFeed,
          minHandlingTime: details.minHandlingTimeFeed,
          pickupSLA: details.pickupSLAFeed,
        };
        commit("set_shippingLabels", shippingLabels);
        //set variants
        commit("set_variants", details.variants);
      } catch (err) {}
    },
    async saveProductDetails({ commit, state }) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        let data = { shopId, productIds: [state.productIdToEdit] };
        let fieldsToEdit = {};
        const editedBasicDetails = state.basicDetails;
        const basicDetails = {
          title: editedBasicDetails.productTitle,
          body_html: editedBasicDetails.description,
          productHighlight: editedBasicDetails.productHighlights,
          product_type: editedBasicDetails.productType,
          pauseFeed: editedBasicDetails.pause,
        };
        fieldsToEdit = { ...fieldsToEdit, ...basicDetails };
        fieldsToEdit.image = state.media[0];
        fieldsToEdit.images = state.media;
        fieldsToEdit.googleProductCategoryFeed =
          state.googleProductCategory.title;
        fieldsToEdit.fbProductCategoryFeed =
          state.facebookProductCategory.title;
        const editedCustomAttributes = state.customAttributes;
        const customAttributes = {
          colorFeed: editedCustomAttributes.color,
          sizeFeed: editedCustomAttributes.size,
          materialFeed: editedCustomAttributes.material,
          patternFeed: editedCustomAttributes.pattern,
          energyEfficiencyClassFeed:
            editedCustomAttributes.energyEfficiencyClass,
          maxEnergyEfficiencyClassFeed:
            editedCustomAttributes.maxEnergyEfficiencyClass,
          minEnergyEfficiencyClassFeed:
            editedCustomAttributes.minEnergyEfficiencyClass,
          productHeight: editedCustomAttributes.productHeight,
          productHeightUnit: editedCustomAttributes.productHeightUnit,
          productLength: editedCustomAttributes.productLength,
          productLengthUnit: editedCustomAttributes.productLengthUnit,
          productWidth: editedCustomAttributes.productWidth,
          productWidthUnit: editedCustomAttributes.productWidthUnit,
        };
        fieldsToEdit = { ...fieldsToEdit, ...customAttributes };
        const editedDetailedProductCharacteristics =
          state.detailedProductCharacteristics;
        const detailedProductCharacteristics = {
          conditionFeed: editedDetailedProductCharacteristics.condition,
          ageGroupFeed: editedDetailedProductCharacteristics.ageGroup
            ? editedDetailedProductCharacteristics.ageGroup.title
            : null,
          genderFeed: editedDetailedProductCharacteristics.gender
            ? editedDetailedProductCharacteristics.gender.title
            : null,
          sizeSystemFeed: editedDetailedProductCharacteristics.sizeSystem,
          sizeTypeFeed: editedDetailedProductCharacteristics.sizeType,
          unitPricingBaseMeasureFeed:
            editedDetailedProductCharacteristics.unitPricingBaseMeasure,
          unitPricingBaseMeasureUnitFeed:
            editedDetailedProductCharacteristics.unitPricingBaseMeasureUnit,
          unitPricingMeasureFeed:
            editedDetailedProductCharacteristics.unitPricingMeasure,
          unitPricingMeasureUnitFeed:
            editedDetailedProductCharacteristics.unitPricingMeasureUnit,
          adultOrientedFeed: editedDetailedProductCharacteristics.adultOriented,
        };
        fieldsToEdit = { ...fieldsToEdit, ...detailedProductCharacteristics };
        const editedProductLabels = state.productLabels;
        const productLabels = {
          customLabelFeed0: editedProductLabels.customLabel0,
          customLabelFeed1: editedProductLabels.customLabel1,
          customLabelFeed2: editedProductLabels.customLabel2,
          customLabelFeed3: editedProductLabels.customLabel3,
          customLabelFeed4: editedProductLabels.customLabel4,
        };
        fieldsToEdit = { ...fieldsToEdit, ...productLabels };
        const editedAdLabels = state.adLabels;
        const adLabels = {
          adsGroupingFeed: editedAdLabels.adsGrouping,
          adsLabelFeed: editedAdLabels.adsLabel,
          displayAdsIdFeed: editedAdLabels.displayAdsId,
          displayAdsLinkFeed: editedAdLabels.displayAdsLink,
          displayAdsSimilarIdFeed: editedAdLabels.displayAdsSimilarId,
          displayAdsTitle: editedAdLabels.displayAdsTitle,
          displayAdsValueFeed: editedAdLabels.displayAdsValue,
          promotionIdFeed: editedAdLabels.promotionId,
        };
        fieldsToEdit = { ...fieldsToEdit, ...adLabels };
        const editedShippingLabels = state.shippingLabels;
        const shippingLabel = {
          shippingLabelFeed: editedShippingLabels.shippingLabel,
          returnPolicyFeed: editedShippingLabels.returnPolicy,
          shippingFeed: editedShippingLabels.shipping,
          shippingHeight: editedShippingLabels.shippingHeight,
          shippingHeightUnit: editedShippingLabels.shippingHeightUnit,
          shippingLength: editedShippingLabels.shippingLength,
          shippingLengthUnit: editedShippingLabels.shippingLengthUnit,
          shippingWidth: editedShippingLabels.shippingWidth,
          shippingWidthUnit: editedShippingLabels.shippingWidthUnit,
          maxHandlingTimeFeed: editedShippingLabels.maxHandlingTime,
          minHandlingTimeFeed: editedShippingLabels.minHandlingTime,
          pickupSLAFeed: editedShippingLabels.pickupSLA,
        };
        fieldsToEdit = { ...fieldsToEdit, ...shippingLabel };
        data = { ...data, ...fieldsToEdit };
        const response = await services.apiCallWithData(
          "/catalogue/bulkUpdateProducts",
          data
        );
        return response;
      } catch (err) {}
    },
  },
};
