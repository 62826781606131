import Vue from "vue";
import VueRouter from "vue-router";
import encryptor from "@/plugins/encryption";
import store from "../store";
import isEmpty from "lodash/isEmpty";
Vue.use(VueRouter);

const routes = [
  /* new onboarding just for testing */
  {
    path: "/newstoresetups",
    name: "newonboarding2",
    component: () => import("../views/newStoreSetup/storeSetup.vue"),
    meta: { requiresAuth: false },
  },
  /* schedule just for testing */
  {
    path: "/schedule",
    name: "schedule",
    component: () => import("../views/newStoreSetup/schedule.vue"),
    meta: { requiresAuth: false },
  },
  // No Home Page - JUST FOR REDIRECT TO GET STARTED
  {
    path: "/",
    name: "home",
    meta: { requiresAuth: false },
    beforeEnter: Initialredirection,
  },
  {
    path: "/get-started",
    name: "getStarted",
    component: () => import("../views/getStarted.vue"),
    meta: { requiresAuth: false },
  },
  // Email Verification Route
  // {
  //   path: "/signup-mail",
  //   name: "signup-mail",
  //   component: () => import("../views/signUp-mail.vue"),
  //   meta: { requiresAuth: false },
  // },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/errorPage.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/userVerify",
    name: "userVerify",
    component: () => import("../views/userVerify.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/panel",
    name: "panel",
    component: () => import("../views/panel/panelFrame.vue"),
    meta: { requiresAuth: false },
    redirect: {
      name: "dashboard",
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/panel/dashboardTab/dashboardTab.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "myAccount",
        name: "myAccount",
        component: () => import("../views/panel/myAccount/myAccount.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "catalog",
        name: "catalog",
        component: () => import("../views/panel/catalog/catalog.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "integration",
        name: "integration",
        component: () =>
          import("../views/panel/integration/IntegrationView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "integration/:integrationCard",
        name: "integration.manage",
        component: () =>
          import("../views/panel/integration/ManageIntegration.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "edit-product",
        name: "editProduct",
        component: () =>
          import("../views/panel/catalog/revampedEditProduct.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "connecting",
        name: "connecting",
        component: () => import("../views/panel/connector/connectMain.vue"),
        meta: { requiresAuth: true },
        redirect: {
          name: "connecting.connectGoogle",
        },
        children: [
          {
            path: "connect-google",
            name: "connecting.connectGoogle",
            component: () =>
              import("../views/panel/connector/connectGoogle.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "merchant-setup",
            name: "connecting.merchantId",
            component: () =>
              import("../views/panel/connector/google/selectMerchantId.vue"),
            meta: { requiresAuth: true },
            beforeEnter: connectingRedirect,
          },
          {
            path: "merchant-program",
            name: "connecting.merchantProgram",
            component: () =>
              import("../views/panel/connector/merchant/programEnabled.vue"),
            meta: { requiresAuth: true },
            beforeEnter: connectingRedirect,
          },
          {
            path: "merchant-domain",
            name: "connecting.merchantDomain",
            component: () =>
              import("../views/panel/connector/merchant/verifyDomain.vue"),
            meta: { requiresAuth: true },
            beforeEnter: connectingRedirect,
          },
          {
            path: "merchant-shipping",
            name: "connecting.merchantShipping",
            component: () =>
              import("../views/panel/connector/merchant/verifyShipping.vue"),
            meta: { requiresAuth: true },
            beforeEnter: connectingRedirect,
          },
          {
            path: "merchant-product",
            name: "connecting.merchantProduct",
            component: () =>
              import("../views/panel/connector/merchant/insertProduct.vue"),
            meta: { requiresAuth: true },
            beforeEnter: connectingRedirect,
          },
        ],
      },
      {
        path: "reporting",
        name: "reporting",
        component: () =>
          import("../views/panel/reporting/googleanalytics/reportingMain.vue"),
        meta: { requiresAuth: true },
        redirect: {
          name: "reporting.connectGoogle",
        },
        children: [
          {
            path: "connect-google",
            name: "reporting.connectGoogle",
            component: () =>
              import(
                "../views/panel/reporting/googleanalytics/connectGoogle.vue"
              ),
            meta: { requiresAuth: true },
            beforeEnter: reportingRedirect,
          },
          {
            path: "analytics-setup",
            name: "reporting.GASetup",
            component: () =>
              import(
                "../views/panel/reporting/googleanalytics/googleAnalyticsSetup.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "ecommerce-reports",
            name: "reporting.ecommerce-reports",
            component: () =>
              import("../views/panel/reporting/reports/ecommerce-reports.vue"),
            meta: { requiresAuth: true },
            beforeEnter: reportsRedirect,
          },
          {
            path: "wait-report",
            name: "reporting.waitForReport",
            component: () =>
              import(
                "../views/panel/reporting/googleanalytics/waitForReport.vue"
              ),
            meta: { requiresAuth: true },
            beforeEnter: reportsRedirect,
          },
        ],
      },
      {
        path: "googleadsreporting",
        name: "googleadsreporting",
        component: () =>
          import("../views/panel/reporting/googleads/reportingMain.vue"),
        meta: { requiresAuth: true },
        redirect: {
          name: "googleadsreporting.connectGoogle",
        },
        children: [
          {
            path: "connect-google",
            name: "googleadsreporting.connectGoogle",
            component: () =>
              import("../views/panel/reporting/googleads/connectGoogle.vue"),
            meta: { requiresAuth: true },
            beforeEnter: googleAdsReportingRedirect,
          },
          {
            path: "google-ads-setup",
            name: "googleadsreporting.googleAds",
            component: () =>
              import("../views/panel/reporting/googleads/googleAdsSetup.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "wait-report",
            name: "googleadsreporting.waitForReport",
            component: () =>
              import("../views/panel/reporting/googleads/waitForReport.vue"),
            meta: { requiresAuth: true },
            beforeEnter: reportsRedirect,
          },
        ],
      },
      {
        path: "fbreporting",
        name: "fbreporting",
        component: () =>
          import("../views/panel/reporting/facebook/reportingMain.vue"),
        meta: { requiresAuth: true },
        redirect: {
          name: "fbreporting.connectFb",
        },
        children: [
          {
            path: "connect-fb",
            name: "fbreporting.connectFb",
            component: () =>
              import("../views/panel/reporting/facebook/connectFb.vue"),
            meta: { requiresAuth: true },
            beforeEnter: fbReportingRedirect,
          },
          {
            path: "fb-info",
            name: "fbreporting.fbInfo",
            component: () =>
              import("../views/panel/reporting/facebook/selectFbAdsId.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "wait-report",
            name: "fbreporting.waitForReport",
            component: () =>
              import("../views/panel/reporting/facebook/waitForReport.vue"),
            meta: { requiresAuth: true },
            beforeEnter: reportsRedirect,
          },
        ],
      },
      {
        path: "amazonadsreporting",
        name: "amazonadsreporting",
        component: () =>
          import("../views/panel/reporting/amazonads/reportingMain.vue"),
        meta: { requiresAuth: true },
        redirect: {
          name: "amazonadsreporting.connectAmazonAds",
        },
        children: [
          {
            path: "connect-amazon-ads",
            name: "amazonadsreporting.connectAmazonAds",
            component: () =>
              import("../views/panel/reporting/amazonads/connectAmazonAds.vue"),
            meta: { requiresAuth: true },
            beforeEnter: amazonAdsReportingRedirect,
          },
          {
            path: "profile-info",
            name: "amazonadsreporting.profileInfo",
            component: () =>
              import("../views/panel/reporting/amazonads/selectProfileId.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "wait-report",
            name: "amazonadsreporting.waitForReport",
            component: () =>
              import("../views/panel/reporting/amazonads/waitForReport.vue"),
            meta: { requiresAuth: true },
            beforeEnter: reportsAmazonRedirect,
          },
          {
            path: "amazon-reports",
            name: "amazonadsreporting.amazon-reports",
            component: () =>
              import("../views/panel/reporting/reports/amazon-reports.vue"),
            meta: { requiresAuth: true },
            beforeEnter: reportsAmazonRedirect,
          },
        ],
      },
      {
        path: "amazonsellerreporting",
        name: "amazonsellerreporting",
        component: () =>
          import("../views/panel/reporting/amazonseller/reportingMain.vue"),
        meta: { requiresAuth: true },
        redirect: {
          name: "amazonsellerreporting.connectAmazonSeller",
        },
        children: [
          {
            path: "connect-amazon-seller",
            name: "amazonsellerreporting.connectAmazonSeller",
            component: () =>
              import(
                "../views/panel/reporting/amazonseller/connectAmazonSeller.vue"
              ),
            meta: { requiresAuth: true },
            beforeEnter: amazonSellerReportingRedirect,
          },
          {
            path: "profile-info",
            name: "amazonsellerreporting.profileInfo",
            component: () =>
              import(
                "../views/panel/reporting/amazonseller/selectProfileId.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "wait-report",
            name: "amazonsellerreporting.waitForReport",
            component: () =>
              import("../views/panel/reporting/amazonseller/waitForReport.vue"),
            meta: { requiresAuth: true },
            beforeEnter: reportsAmazonRedirect,
          },
        ],
      },
      {
        path: "fbconnecting",
        name: "fbconnecting",
        component: () => import("../views/panel/connector/connectMain.vue"),
        meta: { requiresAuth: true },
        redirect: {
          name: "fbconnecting.fbCatalog",
        },
        children: [
          {
            path: "connect-fb-catalog",
            name: "fbconnecting.fbCatalog",
            component: () =>
              import("../views/panel/connector/fbCatalog/connectFb.vue"),
            meta: { requiresAuth: true },
            beforeEnter: fbConnectingRedirect,
          },
          {
            path: "fb-set-info",
            name: "fbconnecting.fbSetInfo",
            component: () =>
              import("../views/panel/connector/fbCatalog/selectFbInfo.vue"),
            meta: { requiresAuth: true },
            beforeEnter: fbConnectingRedirect,
          },
          {
            path: "fb-catalog-product",
            name: "fbconnecting.fbCatalogProduct",
            component: () =>
              import("../views/panel/connector/fbCatalog/insertFbProduct.vue"),
            meta: { requiresAuth: true },
            beforeEnter: fbConnectingRedirect,
          },
        ],
      },
    ],
  },
  {
    path: "/puppeteer-ecommerce-report",
    name: "puppeteer-ecommerce-report",
    component: () =>
      import("../views/panel/reporting/reports/puppeteerEcommerceReport.vue"),
  },
  {
    path: "/puppeteer-amazon-report",
    name: "puppeteer-amazon-report",
    component: () =>
      import("../views/panel/reporting/reports/puppeteerAmazonReport.vue"),
  },
  {
    path: "/storeSetup",
    name: "storeSetup",
    component: () => import("../views/storeSetup/storeSetup.vue"),
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/verify-setup",
  //   name: "verify-setup",
  //   component: () => import("../views/storeSetup/verifySetup.vue"),
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/pageNotFound.vue"),
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  routes,
});

// Shopify Setup
async function Initialredirection(to, from, next) {
  if (encryptor.decrypt(localStorage.getItem("accessToken"))) {
    if (encryptor.decrypt(localStorage.getItem("organizationId"))) {
      await store.dispatch("storeSetup/getStoreStepsCompleteStatus", {
        organization: encryptor.decrypt(localStorage.getItem("organizationId")),
      });
      await store.dispatch("subscriptionPlan/getActiveSubscription", {
        shopId: encryptor.decrypt(localStorage.getItem("shopId")),
      });
      if (
        store.state.subscriptionPlan.subscription &&
        !isEmpty(store.state.subscriptionPlan.subscription)
      ) {
        if (store.state.storeSetup.storeStepsCompleteStatus) {
          next("/panel/dashboard");
        } else {
          next("/storeSetup?step=3");
        }
      } else {
        // PAID SUBSCRIPTION
        // next("/storeSetup?step=1");
        next("/storeSetup?step=2");
        // PAID SUBSCRIPTION END
      }
    } else {
      next("/storeSetup?step=1");
    }
  } else {
    next("/get-started");
  }
}

// Reports Redirect
async function reportsRedirect(to, from, next) {
  await store.dispatch("reporting/checkReportingSetup");
  // const checkResponse = await this.$store.dispatch(
  //   "dashboardTab/checkSynchronizationStatus"
  // );
  if (!isEmpty(store.state.reporting.reportingSetup)) {
    const setup = store.state.reporting.reportingSetup;
    let routerName;

    if (!isEmpty(setup.weekFetching)) {
      if (
        setup.weekFetching.facebookAds ||
        setup.weekFetching.googleAds ||
        setup.weekFetching.googleAnalytics
      ) {
        if (setup.weekFetching.facebookAds && !setup.weekFBReport) {
          routerName = {
            name: "fbreporting.waitForReport",
          };
        } else if (setup.weekFetching.googleAds && !setup.weekGoogleAdsReport) {
          routerName = {
            name: "googleadsreporting.waitForReport",
          };
        } else if (
          setup.weekFetching.googleAnalytics &&
          !setup.weekGoogleAnalyticsReport
        ) {
          routerName = {
            name: "reporting.waitForReport",
          };
        } else {
          routerName = {
            name: "reporting.ecommerce-reports",
          };
        }
      } else {
        routerName = {
          name: "reporting.ecommerce-reports",
        };
        // if (checkResponse.data.data.responseStatus == "Completed") {
        // } else {
        //   routerName = {
        //     name: "storeSetup",
        //     query: { step: 3 },
        //   };
        // }
      }
    } else {
      routerName = {
        name: "reporting.ecommerce-reports",
      };
      // if (checkResponse.data.data.responseStatus == "Completed") {
      // } else {
      //   routerName = {
      //     name: "storeSetup",
      //     query: { step: 3 },
      //   };
      // }
    }

    if (to.name == routerName.name) {
      next();
    } else {
      router.push(routerName);
    }
  } else if (
    store.state.reporting.setupStatusError == "No State Related to the User"
  ) {
    store.commit("reporting/set_setupStatusError", null);
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  } else {
    next({
      name: "reporting.ecommerce-reports",
    });
  }
}

// Reports Amazon Redirect
async function reportsAmazonRedirect(to, from, next) {
  await store.dispatch("reporting/checkReportingSetup");
  if (!isEmpty(store.state.reporting.reportingSetup)) {
    const setup = store.state.reporting.reportingSetup;
    let routerName;
    if (!isEmpty(setup.weekFetching)) {
      if((setup.amazonAdsInstalled && setup.amazonAds) && (setup.amazonSeller && setup.amazonSellerInstalled)){
        if(setup.weekFetching.amazonAds && setup.weekFetching.amazonSeller){
          if (!setup.weekAmazonAdsReport && !setup.weekAmazonSellerReport) {
            routerName = {
              name: "amazonadsreporting.waitForReport",
            };
          } else if (!setup.weekAmazonSellerReport && setup.weekAmazonAdsReport) {
            routerName = {
              name: "amazonsellerreporting.waitForReport",
            };
          } else if(setup.weekAmazonSellerReport && !setup.weekAmazonAdsReport){
            routerName = {
              name: "amazonadsreporting.waitForReport",
            };
          }else {
            routerName = {
              name: "amazonadsreporting.amazon-reports",
            };
          }
        }else if(!setup.weekFetching.amazonAds && setup.weekFetching.amazonSeller){
          routerName = {
            name: "amazonadsreporting.waitForReport",
          };
        }else if(setup.weekFetching.amazonAds && !setup.weekFetching.amazonSeller){
          routerName = {
            name: "amazonsellerreporting.waitForReport",
          };
        }else{
          routerName = {
            name: "amazonadsreporting.waitForReport",
          };
        }
      }else if(!(setup.amazonAdsInstalled && setup.amazonAds) && (setup.amazonSeller && setup.amazonSellerInstalled)){
        if(setup.weekFetching.amazonSeller && setup.weekAmazonSellerReport){
          routerName = {
            name: "amazonadsreporting.amazon-reports",
          };
        }else{
          routerName = {
            name: "amazonsellerreporting.waitForReport",
          };
        }
      }else if((setup.amazonAdsInstalled && setup.amazonAds) && !(setup.amazonSeller && setup.amazonSellerInstalled)){
        if(setup.weekFetching.amazonAds && setup.weekAmazonAdsReport){
          routerName = {
            name: "amazonadsreporting.amazon-reports",
          };
        }else{
          routerName = {
            name: "amazonadsreporting.waitForReport",
          };
        }
      }else{
        routerName = {
          name: "storeSetup",
          query: { step: 3 },
        };
      }
      
      // if (setup.weekFetching.amazonAds || setup.weekFetching.amazonSeller) {
      //   if (setup.weekFetching.amazonAds && !setup.weekAmazonAdsReport) {
      //     routerName = {
      //       name: "amazonadsreporting.waitForReport",
      //     };
      //   } else if (
      //     setup.weekFetching.amazonSeller &&
      //     !setup.weekAmazonSellerReport
      //   ) {
      //     routerName = {
      //       name: "amazonsellerreporting.waitForReport",
      //     };
      //   } else {
      //     routerName = {
      //       name: "amazonadsreporting.amazon-reports",
      //     };
      //   }
      // } else {
      //   if(setup.amazonAds && setup.amazonAdsInstalled){
      //     routerName = {
      //       name:"amazonadsreporting.waitForReport"
      //       // name: "storeSetup",
      //       // query: { step: 3 },
      //     };
      //   }else if(setup.amazonSeller && setup.amazonSellerInstalled){
      //     routerName = {
      //       name:"amazonsellerreporting.waitForReport"
      //       // name: "storeSetup",
      //       // query: { step: 3 },
      //     };
      //   }
      // }
    } else {
      routerName = {
        name: "storeSetup",
        query: { step: 3 },
      };
    }

    if (to.name == routerName.name) {
      next();
    } else {
      router.push(routerName);
    }
  } else {
    if (
      store.state.reporting.setupStatusError == "No State Related to the User"
    ) {
      store.commit("reporting/set_setupStatusError", null);
    }
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  }
}

// Google Analytics
async function reportingRedirect(to, from, next) {
  await store.dispatch("reporting/checkReportingSetup");
  if (!isEmpty(store.state.reporting.reportingSetup)) {
    const setup = store.state.reporting.reportingSetup;
    let routerName;
    if (!setup.googleAnalyticsInstalled || !setup.googleAnalytics) {
      if (to.name == "reporting.ecommerce-reports") {
        if (
          !isEmpty(setup.weekFetching) &&
          ((setup.weekFetching.facebookAds && setup.weekFBReport) ||
            (setup.weekFetching.googleAds && setup.weekGoogleReport))
        ) {
          routerName = {
            name: "reporting.ecommerce-reports",
          };
        } else {
          routerName = {
            name: "storeSetup",
          };
        }
      } else {
        routerName = {
          name: "storeSetup",
        };
      }
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.googleAnalytics ||
      !setup.weekGoogleReport
    ) {
      routerName = {
        name: "reporting.waitForReport",
      };
    } else if (
      !isEmpty(setup.weekFetching) &&
      ((setup.weekFetching.facebookAds && setup.weekFBReport) ||
        ((setup.weekFetching.googleAds || setup.weekFetching.googleAnalytics) &&
          setup.weekGoogleReport))
    ) {
      routerName = {
        name: "reporting.ecommerce-reports",
      };
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.facebookAds ||
      !setup.weekFBReport
    ) {
      routerName = {
        name: "fbreporting.waitForReport",
      };
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.googleAds ||
      !setup.weekGoogleReport
    ) {
      routerName = {
        name: "googleadsreporting.waitForReport",
      };
    }

    if (to.name == routerName.name) {
      next();
    } else {
      if (routerName.name == "storeSetup") next("/storeSetup?step=3");
      else router.push(routerName);
    }
  } else if (
    store.state.reporting.setupStatusError == "No State Related to the User"
  ) {
    store.commit("reporting/set_setupStatusError", null);
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  } else {
    router.push({ name: "dashboard" });
  }
}

// Google Ads
async function googleAdsReportingRedirect(to, from, next) {
  await store.dispatch("reporting/checkReportingSetup");
  if (!isEmpty(store.state.reporting.reportingSetup)) {
    const setup = store.state.reporting.reportingSetup;
    let routerName;
    if (!setup.googleAdsInstalled || !setup.googleAds) {
      if (to.name == "reporting.ecommerce-reports") {
        if (
          !isEmpty(setup.weekFetching) &&
          ((setup.weekFetching.facebookAds && setup.weekFBReport) ||
            (setup.weekFetching.googleAnalytics && setup.weekGoogleReport))
        ) {
          routerName = {
            name: "reporting.ecommerce-reports",
          };
        } else {
          routerName = {
            name: "storeSetup",
          };
        }
      } else {
        routerName = {
          name: "storeSetup",
        };
      }
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.googleAds ||
      !setup.weekGoogleReport
    ) {
      routerName = {
        name: "googleadsreporting.waitForReport",
      };
    } else if (
      !isEmpty(setup.weekFetching) &&
      ((setup.weekFetching.facebookAds && setup.weekFBReport) ||
        ((setup.weekFetching.googleAds || setup.weekFetching.googleAnalytics) &&
          setup.weekGoogleReport))
    ) {
      routerName = {
        name: "reporting.ecommerce-reports",
      };
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.facebookAds ||
      !setup.weekFBReport
    ) {
      routerName = {
        name: "fbreporting.waitForReport",
      };
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.googleAnalytics ||
      !setup.weekGoogleReport
    ) {
      routerName = {
        name: "reporting.waitForReport",
      };
    }

    if (to.name == routerName.name) {
      next();
    } else {
      if (routerName.name == "storeSetup") next("/storeSetup?step=3");
      else router.push(routerName);
    }
  } else if (
    store.state.reporting.setupStatusError == "No State Related to the User"
  ) {
    store.commit("reporting/set_setupStatusError", null);
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  } else {
    router.push({ name: "dashboard" });
  }
}

// Meta Ads
async function fbReportingRedirect(to, from, next) {
  await store.dispatch("reporting/checkReportingSetup");
  if (!isEmpty(store.state.reporting.reportingSetup)) {
    const setup = store.state.reporting.reportingSetup;
    let routerName;
    if (!setup.facebookInstalled || !setup.facebookAds) {
      routerName = {
        name: "storeSetup",
      };
    } else if (
      !isEmpty(setup.weekFetching) &&
      ((setup.weekFetching.facebookAds && setup.weekFBReport) ||
        ((setup.weekFetching.googleAds || setup.weekFetching.googleAnalytics) &&
          setup.weekGoogleReport))
    ) {
      routerName = {
        name: "reporting.ecommerce-reports",
      };
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.facebookAds ||
      !setup.weekFBReport
    ) {
      routerName = {
        name: "fbreporting.waitForReport",
      };
    } else {
      if (
        isEmpty(setup.weekFetching) ||
        !setup.weekFetching.googleAds ||
        !setup.weekGoogleReport
      ) {
        routerName = {
          name: "googleadsreporting.waitForReport",
        };
      } else if (
        isEmpty(setup.weekFetching) ||
        !setup.weekFetching.googleAnalytics ||
        !setup.weekGoogleReport
      ) {
        routerName = {
          name: "reporting.waitForReport",
        };
      }
    }
    if (to.name == routerName.name) {
      next();
    } else {
      if (routerName.name == "storeSetup") next("/storeSetup?step=3");
      else router.push(routerName);
    }
  } else if (
    store.state.reporting.setupStatusError == "No State Related to the User"
  ) {
    store.commit("reporting/set_setupStatusError", null);
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  } else {
    router.push({ name: "dashboard" });
  }
}

// Amazon Ads
async function amazonAdsReportingRedirect(to, from, next) {
  await store.dispatch("reporting/checkReportingSetup");
  if (!isEmpty(store.state.reporting.reportingSetup)) {
    const setup = store.state.reporting.reportingSetup;
    let routerName;
    if (!setup.amazonAdsInstalled || !setup.amazonAds) {
      if (to.name == "amazonadsreporting.amazon-reports") {
        if (
          !isEmpty(setup.weekFetching) &&
          setup.weekFetching.amazonSeller &&
          setup.weekAmazonSellerReport
        ) {
          routerName = {
            name: "amazonadsreporting.amazon-reports",
          };
        } else if (
          isEmpty(setup.weekFetching) ||
          !setup.weekFetching.amazonSeller ||
          !setup.weekAmazonSellerReport
        ) {
          routerName = {
            name: "amazonsellerreporting.waitForReport",
          };
        } else {
          routerName = {
            name: "storeSetup",
          };
        }
      } else {
        routerName = {
          name: "storeSetup",
        };
      }
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.amazonAds ||
      !setup.weekAmazonAdsReport
    ) {
      routerName = {
        name: "amazonadsreporting.waitForReport",
      };
    } else if (
      (!isEmpty(setup.weekFetching) &&
        setup.weekFetching.amazonAds &&
        setup.weekAmazonAdsReport) ||
      (!isEmpty(setup.weekFetching) &&
        setup.weekFetching.amazonSeller &&
        setup.weekAmazonSellerReport)
    ) {
      routerName = {
        name: "amazonadsreporting.amazon-reports",
      };
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.amazonSeller ||
      !setup.weekAmazonSellerReport
    ) {
      routerName = {
        name: "amazonsellerreporting.waitForReport",
      };
    }

    if (to.name == routerName.name) {
      next();
    } else {
      if (routerName.name == "storeSetup") next("/storeSetup?step=3");
      else router.push(routerName);
    }
  } else if (
    store.state.reporting.setupStatusError == "No State Related to the User"
  ) {
    store.commit("reporting/set_setupStatusError", null);
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  } else {
    router.push({ name: "dashboard" });
  }
}

// Amazon Seller
async function amazonSellerReportingRedirect(to, from, next) {
  await store.dispatch("reporting/checkReportingSetup");
  if (!isEmpty(store.state.reporting.reportingSetup)) {
    const setup = store.state.reporting.reportingSetup;
    let routerName;
    if (!setup.amazonSellerInstalled || !setup.amazonSeller) {
      routerName = {
        name: "storeSetup",
      };
    } else if (
      (!isEmpty(setup.weekFetching) &&
        setup.weekFetching.amazonAds &&
        setup.weekAmazonAdsReport) ||
      (!isEmpty(setup.weekFetching) &&
        setup.weekFetching.amazonSeller &&
        setup.weekAmazonSellerReport)
    ) {
      routerName = {
        name: "amazonadsreporting.amazon-reports",
      };
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.amazonSeller ||
      !setup.weekAmazonSellerReport
    ) {
      routerName = {
        name: "amazonsellerreporting.waitForReport",
      };
    } else if (
      isEmpty(setup.weekFetching) ||
      !setup.weekFetching.amazonAds ||
      !setup.weekAmazonAdsReport
    ) {
      routerName = {
        name: "amazonadsreporting.waitForReport",
      };
    }

    if (to.name == routerName.name) {
      next();
    } else {
      if (routerName.name == "storeSetup") next("/storeSetup?step=3");
      else router.push(routerName);
    }
  } else if (
    store.state.reporting.setupStatusError == "No State Related to the User"
  ) {
    store.commit("reporting/set_setupStatusError", null);
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  } else {
    router.push({ name: "dashboard" });
  }
}

// Google Merchant Center
async function connectingRedirect(to, from, next) {
  await store.dispatch("connecting/checkConnectingSetup");
  if (!isEmpty(store.state.connecting.connectingSetup)) {
    const setup = store.state.connecting.connectingSetup;
    let routerName;
    if (!setup.googleInstalled) {
      routerName = {
        name: "storeSetup",
      };
    } else if (setup.merchantId == "") {
      routerName = {
        name: "connecting.merchantId",
      };
    } else if (!setup.merchantProgram) {
      routerName = {
        name: "connecting.merchantProgram",
      };
    } else if (!setup.merchantDomain) {
      routerName = {
        name: "connecting.merchantDomain",
      };
    } else if (!setup.merchantShipping) {
      routerName = {
        name: "connecting.merchantShipping",
      };
    } else if (!setup.merchantSetup) {
      routerName = {
        name: "connecting.merchantProduct",
      };
    } else {
      routerName = {
        name: "catalog",
      };
    }
    if (to.name == routerName.name) {
      next();
    } else {
      if (routerName.name == "storeSetup") next("/storeSetup?step=3");
      else router.push(routerName);
    }
  } else if (
    store.state.connecting.setupStatusError == "No State Related to the User"
  ) {
    store.commit("connecting/set_setupStatusError", null);
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  } else {
    router.push({ name: "dashboard" });
  }
}

// Meta Catalogue
async function fbConnectingRedirect(to, from, next) {
  await store.dispatch("fbConnecting/checkConnectingSetup");
  if (!isEmpty(store.state.fbConnecting.connectingSetup)) {
    const setup = store.state.fbConnecting.connectingSetup;
    let routerName;
    if (!setup.facebookInstalled) {
      routerName = {
        name: "storeSetup",
      };
    } else if (!setup.facebookInfo) {
      routerName = {
        name: "fbconnecting.fbSetInfo",
      };
    } else if (!setup.productFeedSetup) {
      routerName = {
        name: "fbconnecting.fbCatalogProduct",
      };
    } else {
      routerName = {
        name: "catalog",
      };
    }
    if (to.name == routerName.name) {
      next();
    } else {
      if (routerName.name == "storeSetup") next("/storeSetup?step=3");
      else router.push(routerName);
    }
  } else if (
    store.state.fbConnecting.setupStatusError == "No State Related to the User"
  ) {
    store.commit("fbConnecting/set_setupStatusError", null);
    if (to.name == "storeSetup") {
      next();
    } else {
      next({
        name: "storeSetup",
        query: { step: 3 },
      });
    }
  } else {
    router.push({ name: "dashboard" });
  }
}

router.beforeEach((to, from, next) => {
  const isAccessToken = localStorage.getItem("accessToken");
  if (to.meta.requiresAuth) {
    if (isAccessToken && isAccessToken != null) {
      next();
    } else {
      next("/get-started");
    }
  } else {
    next();
  }
});

export default router;
