<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import encryptor from "@/plugins/encryption";
import { mapState } from "vuex";
import mixinFile from "@/plugins/mixinFile";
export default {
  mixins: [mixinFile],

  computed: {
    ...mapState({
      subscription: (state) => state.subscriptionPlan.subscription,
    }),
  },
  async mounted() {
    let accesstoken = encryptor.decrypt(localStorage.getItem("accessToken"));
    let shopId = encryptor.decrypt(localStorage.getItem("shopId"));
    if (accesstoken) {
      await this.$store.dispatch("subscriptionPlan/getActiveSubscription", {
        shopId: shopId,
      });
    }
  },
  created() {
    if (process.env.NODE_ENV !== "development") {
      this.gtm();
    }
  },
};
</script>

<style lang="scss">
.container {
  padding: 0 !important;
  max-width: 100% !important;
}

.theme--light.v-application {
  font-family: "DM Sans", sans-serif;
}
</style>
