import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";
import "vue-tel-input/dist/vue-tel-input.css";
import DatePicker from "v-calendar/src/components/DatePicker.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import * as Sentry from "@sentry/vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Icon from "@/components/ui/CustomIcon.vue";

Vue.config.productionTip = false;

Vue.use(Toaster, { timeout: 5000 });

Vue.component("date-picker", DatePicker);

Vue.component("vue-select", vSelect);

// Custom icon component
Vue.component("CustomIcon", Icon);

Vue.use(Viewer, {
  debug: true,
  defaultOptions: {
    zIndex: 9999,
  },
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [process.env.VUE_APP_SENTRY_TARGET, /^\//],
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Vue.mixin({
  data: function () {
    return {
      apiUrlBackend: process.env.VUE_APP_BACKEND_URL,
      privecyPolicyUrl: process.env.VUE_APP_WEBFLOW_URL + "/privacy-policy",
      termsServiceUrl: process.env.VUE_APP_WEBFLOW_URL + "/terms-of-serivce",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
