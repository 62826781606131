import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";

export default {
  namespaced: true,

  state: {
    shopId: "",
    error: "",
    allShopsForOrganization: [],
    loading: false,
  },

  mutations: {
    set_shopId(state, data) {
      state.shopId = data;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_allShopsForOrganization(state, data) {
      state.allShopsForOrganization = data;
    },
    set_loading(state, data) {
      state.loading = data;
    },
  },

  actions: {
    async getAllShopsForOrganization({ commit }) {
      commit("set_loading", true);
      const organizatioId = encryptor.decrypt(
        localStorage.getItem("organizationId")
      );
      const response = await services.apiCallWithData(
        "/organization/getAllShopsForOrganization",
        { organizationId: organizatioId }
      );
      commit("set_loading", false);
      if (
        response.data.status == 200 &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        commit("set_allShopsForOrganization", response.data.data);
        let shopData = response.data.data;
        shopData.forEach((shop) => {
          if (shop && shop.type && shop.type == "Shopify" && shop._id) {
            commit("set_shopId", shop._id);
            // localStorage.setItem("shopId", encryptor.encrypt(shop._id));
          }
        });
      } else {
        commit("set_allShopsForOrganization", []);
        commit("set_shopId", null);
      }
    },
  },
};
