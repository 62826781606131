import services from "../../services/apiServices";
import moment from "moment";
import encryptor from "@/plugins/encryption";

export default {
  namespaced: true,

  state: {
    dashboardReport: [],
    success: "",
    error: "",
    faqData: [],
    faqErrorData: "",
    reportDates: {
      start: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
      end: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
      // + "T00:00:00+05:30",
    },
  },

  mutations: {
    set_dashboardReport(state, data) {
      state.dashboardReport = data;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_error_faq(state, data) {
      state.faqErrorData = data;
    },
    set_faqData(state, data) {
      state.faqData = data;
    },
    set_dates(state, data) {
      state.reportDates = data;
    },
  },

  actions: {
    async getShopifyDashboardReport({ commit }, { startDate, endDate }) {
      const requestData = {
        startDate,
        endDate,
      };
      try {
        const response = await services.apiCallWithData(
          `/report/shopifyDashboardReport`,
          requestData
        );

        if (response.data.data && response.data.status === 200) {
          const dashboardData = response.data.data[0].chart;
          commit("set_dashboardReport", dashboardData);
        } else {
          commit("set_dashboardReport", []);
          commit("set_error", response.data.message || "Something Went Wrong");
        }
      } catch (error) {
        // Handle any errors that may occur during the API call
        commit("set_dashboardReport", []);
        commit("set_error", "An error occurred while fetching data.");
      }
    },

    async getDashboardReport({ commit }) {
      try {
        const response = await services.getApiCallForReporting(
          `/report/getDashboardReport`
        );

        if (response.data.data && response.data.status === 200) {
          const dashboardReport = response.data.data.report;
          commit("set_dashboardReport", dashboardReport);
        } else {
          commit("set_dashboardReport", []);
          commit("set_error", response.data.message || "Something Went Wrong");
        }
      } catch (error) {
        // Handle any errors that may occur during the API call
        commit("set_dashboardReport", []);
        commit("set_error", "An error occurred while fetching data.");
      }
    },

    async getFaqData({ commit }) {
      try {
        const response = await services.apiCallWithGetData(`/faq/getAllFaqs`);

        if (response.data.data && response.data.status === 200) {
          const faqData = response.data.data;
          commit("set_faqData", faqData);
        } else {
          commit("set_faqData", []);
          commit(
            "set_error_faq",
            response.data.message || "Something Went Wrong"
          );
        }
      } catch (error) {
        // Handle any errors that may occur during the API call
        commit("set_faqData", []);
        commit("set_error_faq", "An error occurred while fetching data.");
      }
    },

    async checkSynchronizationStatus({ commit }) {
      try {
        const shopId = encryptor.decrypt(localStorage.getItem("shopId"));
        const response = await services.apiCallWithData(
          "/shop/checkSynchronizationStatus",
          { shopId: shopId }
        );
        return response;
      } catch (err) {}
    },
  },
};
