import encryptor from "@/plugins/encryption";
import axios from "axios";
import { captureException } from "@sentry/vue";
export default {
  namespaced: true,

  state: {
    aiSummaryLoading: false,
    aiSummaryId: "",
    isCachedSummary: false,
    fetchSSE: false,
    error: "",
    aiSummary: [],
    sseSource: null,
  },

  mutations: {
    add_aiSummary(state, data) {
      state.aiSummary.push(data);
    },
    set_aiSummary(state, data) {
      state.aiSummary = data;
    },
    set_aiSummaryLoading(state, data) {
      state.aiSummaryLoading = data;
    },
    set_aiSummaryId(state, data) {
      state.aiSummaryId = data;
    },
    set_isCachedSummary(state, data) {
      state.isCachedSummary = data;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_fetchSSE(state, data) {
      state.fetchSSE = data;
    },
    /*
    set_aiSummarySeenStatus(state, { summary }) {
      const oldSummary = state.aiSummary;

      const newSummary = oldSummary?.map((old) => {
        if (old?.content === summary?.content) {
          return {
            ...old,
            seen: !old?.seen,
          };
        } else return old;
      });

      //sort by seen status
      newSummary?.sort((x, y) => {
        return x.seen === y.seen ? 0 : x.seen ? 1 : -1;
      });
      state.aiSummary = newSummary;
      setAiSummaryLS(newSummary);
    },
    */
  },

  actions: {
    async fetchStreamingAISummary({ commit }, data) {
      const shopId =
        data?.shopId ?? encryptor.decrypt(localStorage.getItem("shopId"));
      const apiKey = process.env.VUE_APP_AI_SECURE_KEY;

      const toSkipCache = false;
      const uploadEndpoint = `${process.env.VUE_APP_AI_SERVICE_API}/api?id=${shopId}&api_key=${apiKey}&skipCache=${toSkipCache}`;
      commit("set_aiSummaryLoading", true);
      try {
        const response = await axios(uploadEndpoint, {
          method: "POST",
          data: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        });

        commit("set_isCachedSummary", response?.data?.isCached);
        //store unique id
        commit("set_aiSummaryId", response?.data?.id);
        commit("set_fetchSSE", true);
        return response;
      } catch (err) {
        console.log("Error while fetching AI Summary", err);
        captureException("Error while fetching AI Summary", err);
        commit(
          "set_error",
          err?.response?.data?.message || "Error in fetching AI Summary."
        );
        return err;
      }
    },

    /*
    async updateSummarySeen({ commit }, data) {
      try {
        const response = await services.apiCallForAI(
          "/ai/seen-status-update",
          data,
          "POST"
        );
        const aiSummaryArray = response?.data?.data?.summary;
        commit("set_aiSummary", aiSummaryArray);
      } catch (err) {
        console.log("Error while setting ai-summary-seen", err);
      }
    },
    */
  },
};
