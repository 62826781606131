import services from "../../services/apiServices";
import isEmpty from "lodash/isEmpty";

export default {
  namespaced: true,

  state: {
    error: "",
    fbAdAccountIdList: [],
    fbBusinessIdList: [],
    fbPageIdList: [],
    fbCatalogIdList: [],
    businessId: null,
    newCatalogModal: false,
    selectedCatalogId: null,
    selectedCatalogName: "",
    setupStatusError: null,
    productStatus: false,
    connectingSetup: {},
  },

  mutations: {
    set_error(state, data) {
      state.error = data;
    },
    set_fbAdAccountIdList(state, data) {
      state.fbAdAccountIdList = data;
    },
    set_fbBusinessIdList(state, data) {
      state.fbBusinessIdList = data;
    },
    set_fbPageIdList(state, data) {
      state.fbPageIdList = data;
    },
    set_fbCatalogIdList(state, data) {
      state.fbCatalogIdList = data;
    },
    set_businessId(state, data) {
      state.businessId = data;
    },
    set_newCatalogModal(state, data) {
      state.newCatalogModal = data;
    },
    set_selectedCatalogId(state, data) {
      state.selectedCatalogId = data;
    },
    set_catalogName(state, data) {
      state.selectedCatalogName = data;
    },
    set_setupStatus(state, data) {
      state.connectingSetup = data;
    },
    set_setupStatusError(state, data) {
      state.setupStatusError = data;
    },
    set_productStatus(state, data) {
      state.productStatus = data;
    },
    update_catalogIdList(state, data) {
      state.fbCatalogIdList.unshift(data);
    },
  },

  actions: {
    async installFbCatalog() {
      try {
        const response = await services.apiCallWithGetData(`/facebook/install`);
        return response;
      } catch (err) {
        console.log("error dispatching installFacebook ", err);
      }
    },
    saveBusinessId({ commit }, payload) {
      commit("set_businessId", payload);
    },
    async getFbAdAccountIdList({ commit }) {
      const response = await services.apiCallWithGetData(
        "/facebook/getAdAccounts"
      );
      if (response.data.status === 200) {
        commit("set_fbAdAccountIdList", response.data.data);
      } else if (response.data.status === 400) {
        commit("set_error", response.data.message);
        commit("set_fbAdAccountIdList", []);
      } else {
        commit("set_error", "Something Went Wrong");
        commit("set_fbAdAccountIdList", []);
      }
    },
    async getFbBusinessIdList({ commit }) {
      const response = await services.apiCallWithGetData(
        "/facebook/getBusinessIds"
      );
      if (response.data.status === 200) {
        commit("set_fbBusinessIdList", response.data.data.data);
      } else if (response.data.status === 400) {
        commit("set_error", response.data.message);
        commit("set_fbBusinessIdList", []);
      } else {
        commit("set_error", "Something Went Wrong");
        commit("set_fbBusinessIdList", []);
      }
    },
    async disconnectFacebookAccount({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/facebook/disconnectAccount",
          data
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getFbPageIdList({ commit }) {
      const response = await services.apiCallWithGetData(
        "/facebook/getFbPagesList"
      );
      if (response.data.status === 200) {
        commit("set_fbPageIdList", response.data.data.data);
      } else if (response.data.status === 400) {
        commit("set_error", response.data.message);
        commit("set_fbPageIdList", []);
      } else {
        commit("set_error", "Something Went Wrong");
        commit("set_fbPageIdList", []);
      }
    },
    async getFbCatalogIdList({ commit }, payload) {
      const businessId = payload;
      const response = await services.apiCallWithGetData(
        `/facebook/getCatalogList?businessId=${businessId}`
      );
      if (response.data.status === 200) {
        commit("set_fbCatalogIdList", response.data.data.data);
      } else if (response.data.status === 400) {
        commit("set_error", response.data.message);
        commit("set_fbCatalogIdList", []);
      } else {
        commit("set_error", "Something Went Wrong");
        commit("set_fbCatalogIdList", []);
      }
    },
    async saveFbInfo({ commit }, payload) {
      const { fbAdAccountId, fbBusinessId, fbCatalogId, fbPageId } = payload;
      const obj = {
        businessId: fbBusinessId.id,
        adAccountId: fbAdAccountId.account_id,
        pageId: fbPageId.id,
        catalogId: fbCatalogId.id,
        catalogName: fbCatalogId.name,
        pixelId: "",
      };
      const response = await services.apiCallWithData(
        "/facebook/savePlatformDetails",
        obj
      );
      return response;
    },
    async newCatalogModal({ commit }, payload) {
      commit("set_newCatalogModal", payload);
    },
    async createNewCatalog({ commit }, payload) {
      try {
        const response = await services.apiCallWithData(
          "/facebook/createNewCatalog",
          payload
        );
        commit("set_selectedCatalogId", response.data.data);
        commit("update_catalogIdList", response.data.data);
      } catch (err) {
        console.log("Error dispatching createNewCatalog ", err);
      }
    },
    async checkConnectingSetup({ commit }) {
      try {
        const response = await services.apiCallWithData("/state/checkSetup");
        if (response.data.status === 200 && !isEmpty(response.data.data)) {
          commit("set_setupStatus", response.data.data);
        } else if (
          response.data.status === 200 &&
          isEmpty(response.data.data)
        ) {
          commit("set_setupStatusError", "No State Related to the User");
        } else if (response.data.status === 400) {
          commit("set_setupStatusError", response.data.message);
          commit("set_setupStatus", {});
        } else {
          commit("set_setupStatusError", "Something Went Wrong");
          commit("set_setupStatus", {});
        }
        // return response;
      } catch (error) {
        console.error(error);
      }
    },
    async insertProducts({ commit }) {
      try {
        const response = await services.apiCallWithData(
          "/facebook/scheduleProductFeed"
        );

        const productFeedId = response.data.data;
        const direct = await services.apiCallWithData(
          "/facebook/directUpload",
          { productFeedId }
        );
        return direct;
      } catch (error) {
        console.log("Error dispatching insertProducts ", error);
      }
    },
  },
};
