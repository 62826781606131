import services from "../../services/apiServices";
import encryptor from "../plugins/encryption";

export default {
  namespaced: true,

  state: {
    checkStatus: false,
    statusFeed: "",
    error: "",
    toasterMsg: "",
    success: null,
    products: 0,
    variants: 0,
    feedDetails: {},
    syncFeed: {},
    allCollections: {},
    allAgeGroup: [
      { title: "adult" },
      { title: "all ages" },
      { title: "teen" },
      { title: "kids" },
      { title: "toddler" },
      { title: "infant" },
      { title: "newborn" },
    ],
    allGender: [{ title: "female" }, { title: "male" }, { title: "unisex" }],
    allGoogleProductCategory: [],
    allFBProductCategory: [],
  },
  mutations: {
    set_checkStatus(state, data) {
      state.checkStatus = data;
    },
    set_statusFeed(state, data) {
      state.statusFeed = data;
    },
    set_error(state, data) {
      state.error = data;
    },
    set_success(state, data) {
      state.success = data;
    },
    set_toasterMsg(state, data) {
      state.toasterMsg = data;
    },
    set_productCount(state, data) {
      state.products = data.products;
      state.variants = data.items;
    },
    set_feedDetails(state, data) {
      state.feedDetails = data;
    },
    set_syncfeed(state, data) {
      state.syncFeed = data;
    },
    set_allcollections(state, data) {
      state.allCollections = data;
    },
    set_allAgeGroup(state, data) {
      state.allAgeGroup = data;
    },
    set_allGender(state, data) {
      state.allGender = data;
    },
    set_allGoogleProductCategory(state, data) {
      state.allGoogleProductCategory = data;
    },
    set_allFBProductCategory(state, data) {
      state.allFBProductCategory = data;
    },
  },
  actions: {
    async checkConfigStatus({ commit }, data) {
      const response = await services.apiCallWithData(
        "/config/checkStatus",
        data
      );
      if (
        response.data.status == 200 &&
        response.data.data &&
        response.data.data.statusFeed
      ) {
        var statusFeed = response.data.data.statusFeed;
        localStorage.setItem("check-status", encryptor.encrypt(statusFeed));
        if (statusFeed == "Completed") {
          commit("set_checkStatus", true);
        } else {
          commit("set_checkStatus", false);
        }
        commit("set_error", "");
      } else if (response.data.status == 400) {
        commit("set_checkStatus", false);
        commit("set_error", response.data.message);
      } else if (response.data.status == 404) {
        const response = await services.apiCallWithData(
          "/config/saveConfig",
          data
        );
        localStorage.setItem("check-status", encryptor.encrypt("pendingBoth"));
        commit("set_checkStatus", false);
        commit("set_error", "");
      } else {
        commit("set_error", "Something went wrong.");
      }
    },
    async getProductCount({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/catalogue/getCatalogueDetails",
          data
        );
        if (response.data.status === 200) {
          if (response.data.data.count && response.data.data) {
            commit("set_productCount", response.data.data.count);
          } else {
            commit("set_productCount", {});
          }
        } else {
          commit("set_productCount", {});
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getFeed({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/config/getFeed",
          data
        );
        if (response.data.status === 200) {
          if (response.data.data.configs && response.data.data) {
            commit("set_feedDetails", response.data.data.configs);
          } else {
            commit("set_feedDetails", {});
          }
        } else {
          commit("set_feedDetails", {});
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateConfig({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/config/updateConfig",
          data
        );
        if (response.status === 200) {
          commit("set_success", true);
          commit("set_toasterMsg", "Updated successfully");
        } else {
          commit("set_success", false);
          commit("set_toasterMsg", "Something went wrong");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getSync({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/config/getSync",
          data
        );
        if (response.data.status === 200) {
          if (response.data.data.data && response.data.data) {
            commit("set_syncfeed", response.data.data.data);
          } else {
            commit("set_syncfeed", {});
          }
        } else {
          commit("set_syncfeed", {});
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllCollections({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/config/getAllCollections",
          data
        );
        if (response.data.status === 200) {
          if (response.data.data.collections && response.data.data) {
            commit("set_allcollections", response.data.data.collections);
          } else {
            commit("set_allcollections", {});
          }
        } else {
          commit("set_allcollections", {});
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getTaxanomyByName({ commit }, name) {
      try {
        const response = await services.apiCallWithData(
          "/googleTaxanomy/getGoogleTaxanomyData",
          { name: name }
        );
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.status &&
          response.data.status == 200
        ) {
          commit("set_allGoogleProductCategory", response.data.data);
          commit("set_error", "");
        }
      } catch (err) {
        commit("set_allGoogleProductCategory", []);
      }
    },
    async getFBTaxanomyByName({ commit }, name) {
      try {
        const response = await services.apiCallWithData(
          "/FbProductCategory/getFbProductCategory",
          { name: name }
        );
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.status &&
          response.data.status == 200
        ) {
          commit("set_allFBProductCategory", response.data.data);
          commit("set_error", "");
        }
      } catch (err) {
        commit("set_allFBProductCategory", []);
      }
    },
  },
};
