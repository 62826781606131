const getGraphConfigLS = (reportType) => {
  return JSON.parse(localStorage.getItem(`${reportType}.graphConfig`));
};

const setGraphConfigLS = (reportType, data) => {
  localStorage.setItem(`${reportType}.graphConfig`, JSON.stringify(data));
};

const getHiddenWidgetsLS = (reportType) => {
  return JSON.parse(localStorage.getItem(`${reportType}.hiddenWidgets`));
};

const setHiddenWidgetsLS = (reportType, data) => {
  const key = `${reportType}.hiddenWidgets`;
  localStorage.setItem(key, JSON.stringify(data));

  //https://stackoverflow.com/a/61178486
  //dispatch custom-event on data updation
  window.dispatchEvent(
    new CustomEvent("hidden-widgets-custom-event", {
      detail: {
        storage: JSON.parse(localStorage?.getItem(key)),
      },
    })
  );
};

const getAiSummaryLS = () => {
  return localStorage.getItem("ai-summary");
};
const setAiSummaryLS = (summary) => {
  localStorage.setItem("ai-summary", JSON.stringify(summary));
};

/**
 * This function is used to clear
 * report-dates whenever ad-accounts are updated.
 * updated= addition, deletion, or removal
 */
const clearReportDates = ({ isAmazon } = { isAmazon: false }) => {
  const key = isAmazon ? "reportAmazonDate" : "reportDate";
  localStorage.removeItem(key);
};

export {
  getGraphConfigLS,
  setGraphConfigLS,
  setHiddenWidgetsLS,
  getHiddenWidgetsLS,
  getAiSummaryLS,
  setAiSummaryLS,
  clearReportDates,
};
