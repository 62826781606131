import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";
import isEmpty from "lodash/isEmpty";

export default {
  namespaced: true,

  state: {
    error: "",
    stateDetails: {},
    connectingSetup: {},
    merchantList: [],
    merchantError: "",
    merchantId: "",
    programStatus: false,
    domainStatus: false,
    shippingStatus: false,
    productStatus: false,
    setupStatusError: null,
    loading: false,
  },

  mutations: {
    set_error(state, data) {
      state.error = data;
    },
    set_stateDetails(state, data) {
      state.stateDetails = data;
    },
    set_setupStatus(state, data) {
      state.connectingSetup = data;
    },
    set_merchantId(state, data) {
      state.merchantId = data;
    },
    set_merchantList(state, data) {
      state.merchantList = data;
    },
    set_merchantError(state, data) {
      state.merchantError = data;
    },
    set_programStatus(state, data) {
      state.programStatus = data;
    },
    set_domainStatus(state, data) {
      state.domainStatus = data;
    },
    set_shippingStatus(state, data) {
      state.shippingStatus = data;
    },
    set_productStatus(state, data) {
      state.productStatus = data;
    },
    set_setupStatusError(state, data) {
      state.setupStatusError = data;
    },
    set_loading(state, data) {
      state.loading = data;
    },
  },

  actions: {
    async saveMerchantId({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/google/saveMerchantId",
          data
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async checkProgramEnabled({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/checkProgramEnabled"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getAccount({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/getAccount"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getAccountStatus({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/getAccountStatus"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getSiteVerification({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/getSiteVerification"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async claimWebsite({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/claimWebsite"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async checkShippingStatus({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/getShippingSettings"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async insertProducts({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/insertProducts"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getToken({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/getToken"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async insertWebResource({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/merchant/insertWebResource"
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getMerchantList({ commit }) {
      try {
        const response = await services.apiCallWithGetData(
          "/google/getMerchantList"
        );
        if (response.data.status === 200) {
          commit("set_merchantList", response.data.data);
        } else if (response.data.status === 400) {
          commit("set_merchantError", response.data.message);
          commit("set_merchantList", []);
        } else {
          commit("set_merchantError", "Something Went Wrong");
          commit("set_merchantList", []);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async disconnectGoogleAccount({ commit }, data) {
      try {
        const response = await services.apiCallWithData(
          "/google/disconnectAccount",
          data
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async checkConnectingSetup({ commit }) {
      try {
        const response = await services.apiCallWithData("/state/checkSetup");
        if (response.data.status === 200 && !isEmpty(response.data.data)) {
          commit("set_setupStatus", response.data.data);
        } else if (
          response.data.status === 200 &&
          isEmpty(response.data.data)
        ) {
          commit("set_setupStatusError", "No State Related to the User");
        } else if (response.data.status === 400) {
          commit("set_setupStatusError", response.data.message);
          commit("set_setupStatus", {});
        } else {
          commit("set_setupStatusError", "Something Went Wrong");
          commit("set_setupStatus", {});
        }
        // return response;
      } catch (error) {
        console.error(error);
      }
    },
    async installGoogle() {
      try {
        const organizationId = encryptor.decrypt(
          localStorage.getItem("organizationId")
        );
        const response = await services.apiCallWithGetData(
          `/google/install?organizationId=${organizationId}`
        );
        return response;
      } catch (err) {}
    },
    async createState({ commit, dispatch }) {
      let shopId = encryptor.decrypt(localStorage.getItem("shopId"));
      const response = await services.apiCallWithoutAccessToken(
        "/state/createState",
        { shopId: shopId }
      );
      if (response.data.status === 200) {
        commit("set_stateDetails", response.data.data.details);
        commit("set_setupStatus", response.data.data.details);
      } else if (response.data.status === 400) {
        commit("set_error", response.data.message);
        commit("set_stateDetails", {});
      } else {
        commit("Something Went Wrong");
        commit("set_stateDetails", {});
      }
    },
  },
};
