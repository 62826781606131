<template>
  <i
    class="custom-icon"
    v-bind:class="{ [icon]: icon, [iconWeight]: iconWeight }"
    v-bind:style="style"
  ></i>
</template>
<script>
export default {
  data() {
    return {
      icon: `ph-${this.name}`,
      style: {
        "font-size": `${this.size}px`,
        color: this.color,
      },
    };
  },
  computed: {
    iconWeight() {
      if (this.isFill) return "ph-fill";
      return "ph";
    },
  },
  props: {
    name: String,
    size: String,
    color: String,
    isFill: Boolean,
  },
};
</script>
<style lang="css">
.custom-icon {
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  font-size: 24px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: currentColor;
}

.custom-icon::before {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
}

.custom-icon::after {
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(1.3);
  width: 100%;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
</style>
