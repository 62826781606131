import services from "../../services/apiServices";
import encryptor from "@/plugins/encryption";

export default {
  namespaced: true,

  state: {
    audience: false,
    catalog: false,
    dashboard: false,
    myAccount: false,
  },
  mutations: {
    set_walthroughStatus(state, data) {
      state.audience = data.audience;
      state.catalog = data.catalogue;
      state.dashboard = data.dashboard;
      state.myAccount = data.myAccount;
    },
  },
  actions: {
    async getWalkthrough({ commit }, data) {
      try {
        const userId = encryptor.decrypt(localStorage.getItem("id"));
        const response = await services.apiCallWithData(
          "/walkthrough/getWalkthrough",
          {
            userId: userId,
          }
        );
        if (response.data.status == 200) {
          commit("set_walthroughStatus", response.data.data);
        } else if (response.data.status == 400) {
          commit("set_walthroughStatus", {
            audience: false,
            catalogue: false,
            dashboard: false,
            myAccount: false,
          });
        }
      } catch (err) {}
    },
    async updateWalkthrough({ commit }, data) {
      try {
        const userId = encryptor.decrypt(localStorage.getItem("id"));
        data.userId = userId;
        const response = await services.apiCallWithData(
          "/walkthrough/update",
          data
        );
      } catch (err) {}
    },
  },
};
