import axios from "axios";
import store from "@/store";
import encryptor from "@/plugins/encryption";
const backendUrl = process.env.VUE_APP_BACKEND_URL;
const userManagement = process.env.VUE_APP_USERMANAGEMENT_URL;
const reportUrl = process.env.VUE_APP_REPORTING_URL;
const fileUploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;

export default {
  apiCallForAI: async (url, data, method = "GET") => {
    return await axios({
      method,
      url: reportUrl + url,
      headers: {
        accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
      },
      data,
    });
  },

  apiCallForReporting: async (url, data) => {
    return await axios
      .post(reportUrl + url, data, {
        headers: {
          accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
        },
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.dispatch("userManagement/signOut");
        }
        return Promise.reject(error);
      });
  },
  apiCallForReportingDownload: async (url, data) => {
    return await axios
      .post(
        reportUrl + url,
        data,
        {
          headers: {
            // Accept: "application/octet-stream",
            accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
          },
        },
        { responseType: "arraybuffer" }
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.dispatch("userManagement/signOut");
        }
        return Object.assign({}, error.response);
      });
  },
  deleteApiCallForReporting: async (url, data) => {
    console.log(
      "access-token",
      encryptor.decrypt(localStorage.getItem("accessToken"))
    );
    return await axios
      .delete(reportUrl + url, {
        headers: {
          accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
        },
        data,
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("delete service error", error);
        // if (
        //   error.response &&
        //   error.response.status &&
        //   error.response.status === 401
        // ) {
        //   store.dispatch("userManagement/signOut");
        // }
        // return Object.assign({}, error.response);
      });
  },
  getApiCallForReporting: async (url) => {
    return await axios
      .get(reportUrl + url, {
        headers: {
          accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
        },
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.dispatch("userManagement/signOut");
        }
        return Object.assign({}, error.response);
      });
  },

  apiCallForReportingWithKeyHeader: async (url, data) => {
    return await axios
      .post(reportUrl + url, data.body, {
        headers: {
          session: data.header,
          accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
        },
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.dispatch("userManagement/signOut");
        }
        return Object.assign({}, error.response);
      });
  },
  apiForReportingWithoutHeader: async (url, data) => {
    return await axios
      .post(reportUrl + url, data)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.dispatch("userManagement/signOut");
        }
        return Object.assign({}, error.response);
      });
  },
  apiCallWithData: async (url, data) => {
    return await axios
      .post(backendUrl + url, data, {
        headers: {
          accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
        },
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.dispatch("userManagement/signOut");
        }
        return Object.assign({}, error.response);
      });
  },
  apiCallWithoutAccessToken: async (url, data) => {
    return await axios
      .post(backendUrl + url, data)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.dispatch("userManagement/signOut");
        }
        return Object.assign({}, error.response);
      });
  },
  apiCallWithGetData: async (url) => {
    return await axios
      .get(backendUrl + url, {
        headers: {
          accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
        },
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.dispatch("userManagement/signOut");
        }
        return Object.assign({}, error.response);
      });
  },
  userApiCallWithData: async (url, data) => {
    return await axios
      .post(userManagement + url, data, {
        headers: {
          accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
        },
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Object.assign({}, error.response);
      });
  },
  userApiCallGetWithoutAccessToken: async (url, data) => {
    return await axios
      .get(userManagement + url, {
        headers: {
          accessToken: encryptor.decrypt(localStorage.getItem("accessToken")),
        },
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Object.assign({}, error.response);
      });
  },
  uploadFile: async (url, data) => {
    return await axios
      .post(fileUploadUrl + url, data)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Object.assign({}, error.response);
      });
  },
};
