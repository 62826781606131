import { getUniqueWidgetKey } from "@/utils/util-fns";

/**
 * This function creats a flat object of all the widgets
 * which are set to false/true and store in local-storage.
 * E.g.
 * {
 * "Top Selling Products":false, //not to render
 * "Top Selling Regions":true, //can render this
 * }
 */

const getHiddenWidgetsForReportsPage = (reportType, categories) => {
  const hiddenWidgets = {};
  const isAmazon = reportType === "Amazon";

  categories?.forEach((category) => {
    // for subcategories
    if (category?.subcategories) {
      category?.subcategories?.forEach((subcategory) => {
        subcategory?.fields?.forEach((subcategoryField) => {
          if (subcategoryField?.subfields) {
            subcategoryField?.subfields?.forEach((subcategorySubfield) => {
              const widgetKey = getUniqueWidgetKey({
                displayName: subcategorySubfield?.value,
                graphConfig: subcategorySubfield?.graphConfig,
                isAmazon,
              });
              hiddenWidgets[widgetKey] = subcategorySubfield?.checked;
            });
          }
        });
      });
    }
    if (category?.fields) {
      category?.fields?.forEach((categoryField) => {
        const widgetKey = getUniqueWidgetKey({
          displayName: categoryField?.value,
          graphConfig: categoryField?.graphConfig,
          isAmazon,
        });
        hiddenWidgets[widgetKey] = categoryField?.checked;
      });
    }
  });

  return hiddenWidgets;
};

/*
if widget present in graphconfig but not in api response, 
update graph config widget checked
*/
const updateCheckedStateForWidgets = (options) => {
  const { isAmazon, widgetList, newCheckedState, graphConfig } = options;

  graphConfig?.forEach((category) => {
    if (category?.fields) {
      category?.fields?.forEach((categoryField) => {
        const key = getUniqueWidgetKey({
          isAmazon,
          displayName: categoryField?.value,
          graphConfig: categoryField?.graphConfig,
        });
        if (widgetList?.indexOf(key) < 0) {
          categoryField.checked = newCheckedState;
        }
      });
    }

    // sub-categories
    if (category?.subcategories) {
      category?.subcategories?.forEach((subcategory) => {
        if (subcategory?.fields) {
          subcategory?.fields?.forEach((subcategoryField) => {
            if (subcategoryField?.subfields) {
              subcategoryField?.subfields?.forEach((subcategorySubfield) => {
                const key = getUniqueWidgetKey({
                  isAmazon,
                  displayName: subcategorySubfield?.value,
                  graphConfig: subcategorySubfield?.graphConfig,
                });

                if (widgetList?.indexOf(key) < 0) {
                  subcategorySubfield.checked = newCheckedState;
                }
              });
            }
          });
        }
      });
    }
  });

  return {
    updatedGraphConfig: graphConfig,
  };
};

export { getHiddenWidgetsForReportsPage, updateCheckedStateForWidgets };
